import axios from "axios";

if (!process.env.REACT_APP_URL) {
    // eslint-disable-next-line no-console
    console.warn("LinguaKit server url is not set.");
}

const baseURL = process.env.REACT_APP_URL ?
    `${process.env.REACT_APP_URL}` :
    "https://api.linguakit.com/v2.0";

class LKAxios {
    constructor() {
        let service = axios.create({
            baseURL,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    handleSuccess(response) {
        return response.data;
    }

    handleError = (error) => {
        return Promise.reject(error);
    };

    setToken = (newToken) => {
        this.token = newToken;
        this.service = axios.create({
            baseURL,
        });
    };
    getToken = () => {
        return this.token;
    };
}

export default new LKAxios();