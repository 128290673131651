import React, { useCallback, useState } from "react";
import styles from "./LKNumberSelector.module.scss";

const LKNumberSelector = ({
  placeholder,
  initialValue,
  minValue = 0,
  maxValue = 100,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  const onHandleChange = useCallback(
    (e) => {
      const value = parseInt(e.target.value);
      if (value < minValue) {
        setValue(minValue);
        if (onChange) {
          onChange(minValue);
        }
      } else {
        if (value > maxValue) {
          setValue(maxValue);
          if (onChange) {
            onChange(maxValue);
          }
        } else {
          setValue(parseInt(e.target.value));
          if (onChange) {
            onChange(parseInt(e.target.value));
          }
        }
      }
    },
    [onChange, maxValue, minValue]
  );

  return (
    <input
      className={styles.numericInput}
      type="number"
      value={value}
      min={minValue}
      max={maxValue}
      onChange={onHandleChange}
      {...props}
    />
  );
};

export default LKNumberSelector;
