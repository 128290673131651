export const ANALYSIS_PAGE = {
  es: "/analisis-completo",
  en: "/full-analysis",
  pt: "/analise-completa",
  gl: "/analise-completa",
};
export const CONJUGATOR_PAGE = {
  es: "/conjugador",
  en: "/conjugator",
  pt: "/conjugador",
  gl: "/conxugador",
};
export const ENTITY_RECOGNIZER_PAGE = {
  es: "/reconocedor-de-entidades",
  en: "/name-entity-recognizer",
  pt: "/reconhecedor-de-entidades",
  gl: "/reconocedor-de-entidades",
};
export const KEYWORD_EXTRACTOR_PAGE = {
  es: "/extractor-de-palabras-clave",
  en: "/keyword-extractor",
  pt: "/extrator-multipalavra",
  gl: "/extrator-de-palavras-chave",
};
export const KEYWORD_PAGE = {
  es: "/palabra-clave-en-contexto",
  en: "/keyword-in-context",
  pt: "/palavra-chave-em-contexto",
  gl: "/palabra-clave-en-contexto",
};
export const LANGUAGE_IDENTIFIER_PAGE = {
  es: "/identificador-de-idioma",
  en: "/language-identifier",
  pt: "/identificador-de-idioma",
  gl: "/identificador-de-idioma",
};
export const MORPHOSYNTACTIC_TAGGER_PAGE = {
  es: "/etiquetador-morfosintactico",
  en: "/part-of-speech-tagging",
  pt: "/etiquetador-morfossintatico",
  gl: "/etiquetador-morfosintactico",
};
export const MULTIWORD_EXTRACTOR_PAGE = {
  es: "/extractor-multipalabra",
  en: "/multiword-extractor",
  pt: "/extrator-multipalavra",
  gl: "/extractor-multipalabra",
};
export const SENTIMENT_ANALYZER_PAGE = {
  es: "/analizador-de-sentimiento",
  en: "/sentiment-analysis",
  pt: "/analisador-de-sentimento",
  gl: "/analizador-de-sentimento",
};
export const SUMMARY_PAGE = {
  es: "/resumidor",
  en: "/summarizer",
  pt: "/resumidor",
  gl: "/resumidor",
};
export const SUPERCORRECTOR_PAGE = {
  es: "/supercorrector",
  en: "/supercorrector",
  pt: "/supercorrector",
  gl: "/supercorrector",
};
export const SYNTACTIC_ANALYZER_PAGE = {
  es: "/analizador-sintactico",
  en: "/syntactic-analyzer",
  pt: "/analisador-sintatico",
  gl: "/analizador-sintactico",
};
export const TRIPLET_EXTRACTOR_PAGE = {
  es: "/extractor-de-tripletas",
  en: "/triples-extractor",
  pt: "/extrator-de-tripletas",
  gl: "/extractor-de-tripletas",
};
export const WORD_FREQUENCY_PAGE = {
  es: "/frecuencia-de-palabras",
  en: "/word-frequency",
  pt: "/frequencia-de-palavras",
  gl: "/frecuencia-de-palabras",
};

export const ABOUT_PAGE = {
  es: "/sobre-linguakit",
  en: "/about-linguakit",
  pt: "/sobre-linguakit",
  gl: "/sobre-linguakit",
};

export const LEGAL_PAGE = {
  es: "/legal",
  en: "/legal",
  pt: "/legal",
  gl: "/legal",
};

const routes = {
  ANALYSIS_PAGE,
  CONJUGATOR_PAGE,
  ENTITY_RECOGNIZER_PAGE,
  KEYWORD_EXTRACTOR_PAGE,
  KEYWORD_PAGE,
  LANGUAGE_IDENTIFIER_PAGE,
  MORPHOSYNTACTIC_TAGGER_PAGE,
  MULTIWORD_EXTRACTOR_PAGE,
  SENTIMENT_ANALYZER_PAGE,
  SUMMARY_PAGE,
  SUPERCORRECTOR_PAGE,
  SYNTACTIC_ANALYZER_PAGE,
  TRIPLET_EXTRACTOR_PAGE,
  WORD_FREQUENCY_PAGE,
  ABOUT_PAGE,
  LEGAL_PAGE,
};

export default routes;
