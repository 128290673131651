function freeling_code_to_readable_pt(codigo) {
  const categorias = {}; // categoria
  categorias["A"] = "Adjetivo";
  categorias["R"] = "Advérbio";
  categorias["D"] = "Determinante";
  categorias["N"] = "Nome";
  categorias["V"] = "Verbo";
  categorias["P"] = "Pronome";
  categorias["C"] = "Conjunção";
  categorias["I"] = "Interjeição";
  categorias["S"] = "Preposição";
  categorias["F"] = "Contagem";
  categorias["Z"] = "Quantidade";
  categorias["W"] = "Data/tempo";

  const genero = {};
  genero["M"] = "Masculino";
  genero["F"] = "Feminino";
  genero["C"] = "Comum";
  genero["N"] = "";
  genero["0"] = "";

  const grau = {};
  grau["0"] = "";
  grau["A"] = "Aumentativo";
  grau["C"] = "Diminutivo";
  grau["S"] = "Superlativo";

  const numero = {};
  numero["S"] = "Singular";
  numero["P"] = "Plural";
  numero["N"] = "Invariável";
  numero["0"] = "";

  const pessoa = {};
  pessoa["1"] = "Primeira Pessoa";
  pessoa["2"] = "Segunda Pessoa";
  pessoa["3"] = "Terceira Pessoa";
  pessoa["0"] = "";

  const possuidor = {};
  possuidor["S"] = "Singular";
  possuidor["P"] = "Plural";
  possuidor["0"] = "Indefinido";

  const tipo_A = {};
  tipo_A["0"] = "";
  tipo_A["O"] = "Ordinal";
  tipo_A["Q"] = "Qualificativo";

  const funcion_A = {};
  funcion_A["0"] = "";
  funcion_A["P"] = "Particípio";

  const tipo_R = {};
  tipo_R["G"] = "Geral";
  tipo_R["N"] = "Negativo";
  tipo_R["0"] = "";

  const tipo_D = {};
  tipo_D["D"] = "Demonstrativo";
  tipo_D["P"] = "Possessivo";
  tipo_D["T"] = "Interrogativo";
  tipo_D["E"] = "Exclamação";
  tipo_D["I"] = "Indefinido";
  tipo_D["A"] = "Artigo";
  tipo_D["0"] = "";

  const tipo_N = {};
  tipo_N["C"] = "Comun";
  tipo_N["P"] = "Própio";
  tipo_N["0"] = "";

  const clasificacion_N1 = {};
  clasificacion_N1["S"] = "Pessoa";
  clasificacion_N1["G"] = "Lugar";
  clasificacion_N1["O"] = "Organização";
  clasificacion_N1["V"] = "Outros";
  clasificacion_N1["0"] = "";

  const clasificacion_N2 = {};
  clasificacion_N2["P"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";

  const tipo_V = {};
  tipo_V["M"] = "Principal";
  tipo_V["A"] = "Auxiliar";
  tipo_V["S"] = "Semiauxiliar";
  tipo_V["0"] = "";

  const modo_V = {};
  modo_V["I"] = "Indicativo";
  modo_V["S"] = "Subjuntivo";
  modo_V["M"] = "Imperativo";
  modo_V["N"] = "Infinitivo";
  modo_V["G"] = "Gerúndio";
  modo_V["P"] = "Particípio";
  modo_V["0"] = "";

  const tiempo_V = {};
  tiempo_V["P"] = "Presente";
  tiempo_V["I"] = "Imperfeito";
  tiempo_V["F"] = "Futuro";
  tiempo_V["S"] = "Passado";
  tiempo_V["C"] = "Condicional";
  tiempo_V["0"] = "";

  const tipo_P = {};
  tipo_P["P"] = "Pessoal";
  tipo_P["D"] = "Demonstrativo";
  tipo_P["X"] = "Possessivo";
  tipo_P["I"] = "Indefinido";
  tipo_P["T"] = "Interrogativo";
  tipo_P["R"] = "Relativo";
  tipo_P["E"] = "Exclamação";
  tipo_P["0"] = "";

  const caso_P = {};
  caso_P["N"] = "Nominativo";
  caso_P["A"] = "Acusativo";
  caso_P["D"] = "Dativo";
  caso_P["O"] = "Oblíquo";
  caso_P["0"] = "";

  const educado_P = {};
  educado_P["P"] = "Educado";
  educado_P["0"] = "";

  const tipo_C = {};
  tipo_C["C"] = "Coordenada";
  tipo_C["S"] = "Subordinada";
  tipo_C["0"] = "";

  const tipo_S = {};
  tipo_S["P"] = "Preposição";

  const forma_S = {};
  forma_S["S"] = "Simples";
  forma_S["C"] = "Contraída";
  forma_S["0"] = "";

  const tipo_Z = {};
  tipo_Z["d"] = "Partitivo";
  tipo_Z["m"] = "Moeda";
  tipo_Z["p"] = "Percentagem";
  tipo_Z["u"] = "Unidade";
  tipo_Z["0"] = "";

  const tipo_F = {};

  tipo_F["aa"] = "";
  tipo_F["at"] = "";
  tipo_F["c"] = "";
  tipo_F["ca"] = "";
  tipo_F["ct"] = "";
  tipo_F["d"] = "";
  tipo_F["e"] = "";
  tipo_F["g"] = "";
  tipo_F["h"] = "";
  tipo_F["ia"] = "";
  tipo_F["it"] = "";
  tipo_F["la"] = "";
  tipo_F["lt"] = "";
  tipo_F["p"] = "";
  tipo_F["pa"] = "";
  tipo_F["pt"] = "";
  tipo_F["ra"] = "";
  tipo_F["rc"] = "";
  tipo_F["s"] = "";
  tipo_F["t"] = "";
  tipo_F["x"] = "";
  tipo_F["0"] = "";

  const posiciones = {};
  posiciones["A"] = [
    "categorias",
    "tipo_A",
    "grau",
    "gênero",
    "numero",
    "função_A",
  ];
  posiciones["R"] = ["categorias", "tipo_R"];
  posiciones["D"] = [
    "categorias",
    "tipo_D",
    "pessoa",
    "gênero",
    "numero",
    "possuidor",
  ];
  posiciones["N"] = [
    "categorias",
    "tipo_N",
    "gênero",
    "numero",
    "clasificacion_N1",
    "clasificacion_N2",
    "grau",
  ];
  posiciones["V"] = [
    "categorias",
    "tipo_V",
    "modo_V",
    "tiempo_V",
    "pessoa",
    "numero",
    "gênero",
  ];
  posiciones["P"] = [
    "categorias",
    "tipo_P",
    "pessoa",
    "gênero",
    "numero",
    "caso_P",
    "possuidor",
    "educado_P",
  ];
  posiciones["C"] = ["categorias", "tipo_C"];
  posiciones["I"] = ["categorias"];
  posiciones["S"] = ["categorias", "tipo_S", "forma_S", "gênero", "numero"];
  posiciones["F"] = ["categorias", "tipo_F"];
  posiciones["Z"] = ["categorias", "tipo_Z"];
  posiciones["W"] = ["categorias"];

  const atributos = {};
  atributos["categorias"] = categorias;
  atributos["gênero"] = genero;
  atributos["grau"] = grau;
  atributos["numero"] = numero;
  atributos["pessoa"] = pessoa;
  atributos["possuidor"] = possuidor;
  atributos["tipo_A"] = tipo_A;
  atributos["função_A"] = funcion_A;
  atributos["tipo_R"] = tipo_R;
  atributos["tipo_D"] = tipo_D;
  atributos["tipo_N"] = tipo_N;
  atributos["clasificacion_N1"] = clasificacion_N1;
  atributos["clasificacion_N2"] = clasificacion_N2;
  atributos["tipo_V"] = tipo_V;
  atributos["modo_V"] = modo_V;
  atributos["tiempo_V"] = tiempo_V;
  atributos["tipo_P"] = tipo_P;

  atributos["caso_P"] = caso_P;
  atributos["educado_P"] = educado_P;
  atributos["tipo_C"] = tipo_C;
  atributos["tipo_S"] = tipo_S;
  atributos["forma_S"] = forma_S;
  atributos["tipo_Z"] = tipo_Z;
  atributos["tipo_F"] = tipo_F;

  const letra_cat = codigo.charAt(0);
  const human_readable_array = [];
  try {
    for (let i = 0; i < codigo.length; i++) {
      const _categoria = posiciones[letra_cat][i];
      let _code = codigo.charAt(i);

      if (i > 0 && letra_cat === "F" && codigo.length > 2) {
        _code += codigo.charAt(i + 1);
        i = codigo.length;
      }

      human_readable_array[i] = atributos[_categoria][_code];
    }
  }
  catch(error) {
    console.error(error);
    return codigo;
  }
  return human_readable_array.join(" ");
}

function freeling_code_to_readable_gl(codigo) {
  const categorias = {}; // categoria
  categorias["A"] = "Adxectivo";
  categorias["R"] = "Adverbio";
  categorias["D"] = "Determinante";
  categorias["N"] = "Nome";
  categorias["V"] = "Verbo";
  categorias["P"] = "Pronome";
  categorias["C"] = "Conxunción";
  categorias["I"] = "Interxección";
  categorias["S"] = "Preposición";
  categorias["F"] = "Puntuación";
  categorias["Z"] = "Cifra";
  categorias["W"] = "Data/hora";

  const xenero = {};
  xenero["M"] = "Masculino";
  xenero["F"] = "Feminino";
  xenero["C"] = "Común";
  xenero["N"] = "";
  xenero["0"] = "";

  const grado = {};
  grado["0"] = "";
  grado["A"] = "Aumentativo";
  grado["C"] = "Diminutivo";
  grado["S"] = "Superlativo";

  const numero = {};
  numero["S"] = "Singular";
  numero["P"] = "Plural";
  numero["N"] = "Invariable";
  numero["0"] = "";

  const persoa = {};
  persoa["1"] = "Primeira Persoa";
  persoa["2"] = "Segunda Persoa";
  persoa["3"] = "Terceira Persoa";
  persoa["0"] = "";

  const posuidor = {};
  posuidor["S"] = "Singular";
  posuidor["P"] = "Plural";
  posuidor["0"] = "Indefinido";

  const tipo_A = {};
  tipo_A["0"] = "";
  tipo_A["O"] = "Ordinal";
  tipo_A["Q"] = "Cualificativo";

  const funcion_A = {};
  funcion_A["0"] = "";
  funcion_A["P"] = "Participio";

  const tipo_R = {};
  tipo_R["G"] = "Xeral";
  tipo_R["N"] = "Negativo";
  tipo_R["0"] = "";

  const tipo_D = {};
  tipo_D["D"] = "Demostrativo";
  tipo_D["P"] = "Posesivo";
  tipo_D["T"] = "Interrogativo";
  tipo_D["E"] = "Exclamativo";
  tipo_D["I"] = "Indefinido";
  tipo_D["A"] = "Artigo";
  tipo_D["0"] = "";

  const tipo_N = {};
  tipo_N["C"] = "Común";
  tipo_N["P"] = "Propio";
  tipo_N["0"] = "";

  const clasificacion_N1 = {};
  clasificacion_N1["S"] = "Persoa";
  clasificacion_N1["G"] = "Lugar";
  clasificacion_N1["O"] = "Organización";
  clasificacion_N1["V"] = "Outros";
  clasificacion_N1["0"] = "";

  const clasificacion_N2 = {};
  clasificacion_N2["P"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";

  const tipo_V = {};
  tipo_V["M"] = "Principal";
  tipo_V["A"] = "Auxiliar";
  tipo_V["S"] = "Semiauxiliar";
  tipo_V["0"] = "";

  const modo_V = {};
  modo_V["I"] = "Indicativo";
  modo_V["S"] = "Subxuntivo";
  modo_V["M"] = "Imperativo";
  modo_V["N"] = "Infinitivo";
  modo_V["G"] = "Xerundio";
  modo_V["P"] = "Participio";
  modo_V["0"] = "";

  const tiempo_V = {};
  tiempo_V["P"] = "Presente";
  tiempo_V["I"] = "Imperfecto";
  tiempo_V["F"] = "Futuro";
  tiempo_V["S"] = "Pasado";
  tiempo_V["C"] = "Condicional";
  tiempo_V["0"] = "";

  const tipo_P = {};
  tipo_P["P"] = "Persoal";
  tipo_P["D"] = "Demostrativo";
  tipo_P["X"] = "Posesivo";
  tipo_P["I"] = "Indefinido";
  tipo_P["T"] = "Interrogativo";
  tipo_P["R"] = "Relativo";
  tipo_P["E"] = "Exclamativo";
  tipo_P["0"] = "";

  const caso_P = {};
  caso_P["N"] = "Nominativo";
  caso_P["A"] = "Acusativo";
  caso_P["D"] = "Dativo";
  caso_P["O"] = "Oblicuo";
  caso_P["0"] = "";

  const educado_P = {};
  educado_P["P"] = "Educado";
  educado_P["0"] = "";

  const tipo_C = {};
  tipo_C["C"] = "Coordinada";
  tipo_C["S"] = "Subordinada";
  tipo_C["0"] = "";

  const tipo_S = {};
  tipo_S["P"] = "Preposición";

  const forma_S = {};
  forma_S["S"] = "Simple";
  forma_S["C"] = "Contraída";
  forma_S["0"] = "";

  const tipo_Z = {};
  tipo_Z["d"] = "Partitivo";
  tipo_Z["m"] = "Moeda";
  tipo_Z["p"] = "Porcentaxe";
  tipo_Z["u"] = "Unidade";
  tipo_Z["0"] = "";

  const tipo_F = {};

  tipo_F["aa"] = "";
  tipo_F["at"] = "";
  tipo_F["c"] = "";
  tipo_F["ca"] = "";
  tipo_F["ct"] = "";
  tipo_F["d"] = "";
  tipo_F["e"] = "";
  tipo_F["g"] = "";
  tipo_F["h"] = "";
  tipo_F["ia"] = "";
  tipo_F["it"] = "";
  tipo_F["la"] = "";
  tipo_F["lt"] = "";
  tipo_F["p"] = "";
  tipo_F["pa"] = "";
  tipo_F["pt"] = "";
  tipo_F["ra"] = "";
  tipo_F["rc"] = "";
  tipo_F["s"] = "";
  tipo_F["t"] = "";
  tipo_F["x"] = "";
  tipo_F["0"] = "";

  const posiciones = {};
  posiciones["A"] = [
    "categorias",
    "tipo_A",
    "grado",
    "xenero",
    "numero",
    "funcion_A",
  ];
  posiciones["R"] = ["categorias", "tipo_R"];
  posiciones["D"] = [
    "categorias",
    "tipo_D",
    "persoa",
    "xenero",
    "numero",
    "posuidor",
  ];
  posiciones["N"] = [
    "categorias",
    "tipo_N",
    "xenero",
    "numero",
    "clasificacion_N1",
    "clasificacion_N2",
    "grado",
  ];
  posiciones["V"] = [
    "categorias",
    "tipo_V",
    "modo_V",
    "tiempo_V",
    "persoa",
    "numero",
    "xenero",
  ];
  posiciones["P"] = [
    "categorias",
    "tipo_P",
    "persoa",
    "xenero",
    "numero",
    "caso_P",
    "posuidor",
    "educado_P",
  ];
  posiciones["C"] = ["categorias", "tipo_C"];
  posiciones["I"] = ["categorias"];
  posiciones["S"] = ["categorias", "tipo_S", "forma_S", "xenero", "numero"];
  posiciones["F"] = ["categorias", "tipo_F"];
  posiciones["Z"] = ["categorias", "tipo_Z"];
  posiciones["W"] = ["categorias"];

  const atributos = {};
  atributos["categorias"] = categorias;
  atributos["xenero"] = xenero;
  atributos["grado"] = grado;
  atributos["numero"] = numero;
  atributos["persoa"] = persoa;
  atributos["posuidor"] = posuidor;
  atributos["tipo_A"] = tipo_A;
  atributos["funcion_A"] = funcion_A;
  atributos["tipo_R"] = tipo_R;
  atributos["tipo_D"] = tipo_D;
  atributos["tipo_N"] = tipo_N;
  atributos["clasificacion_N1"] = clasificacion_N1;
  atributos["clasificacion_N2"] = clasificacion_N2;
  atributos["tipo_V"] = tipo_V;
  atributos["modo_V"] = modo_V;
  atributos["tiempo_V"] = tiempo_V;
  atributos["tipo_P"] = tipo_P;

  atributos["caso_P"] = caso_P;
  atributos["educado_P"] = educado_P;
  atributos["tipo_C"] = tipo_C;
  atributos["tipo_S"] = tipo_S;
  atributos["forma_S"] = forma_S;
  atributos["tipo_Z"] = tipo_Z;
  atributos["tipo_F"] = tipo_F;

  const letra_cat = codigo.charAt(0);
  const human_readable_array = [];
  try {
    for (let i = 0; i < codigo.length; i++) {
      const _categoria = posiciones[letra_cat][i];
      let _code = codigo.charAt(i);

      if (i > 0 && letra_cat === "F" && codigo.length > 2) {
        _code += codigo.charAt(i + 1);
        i = codigo.length;
      }

      human_readable_array[i] = atributos[_categoria][_code];
    }
  }
  catch(error) {
    console.error(error);
    return codigo;
  }
  return human_readable_array.join(" ");
}
function freeling_code_to_readable_es(codigo) {
  const categorias = {}; // categoria
  categorias["A"] = "Adjetivo";
  categorias["R"] = "Adverbio";
  categorias["D"] = "Determinante";
  categorias["N"] = "Nombre";
  categorias["V"] = "Verbo";
  categorias["P"] = "Pronombre";
  categorias["C"] = "Conjunción";
  categorias["I"] = "Interjección";
  categorias["S"] = "Preposición";
  categorias["F"] = "Puntuación";
  categorias["Z"] = "Cifra";
  categorias["W"] = "Fecha/hora";

  const genero = {};
  genero["M"] = "Masculino";
  genero["F"] = "Femenino";
  genero["C"] = "Común";
  genero["N"] = "";
  genero["0"] = "";

  const grado = {};
  grado["0"] = "";
  grado["A"] = "Aumentativo";
  grado["C"] = "Diminutivo";
  grado["S"] = "Superlativo";

  const numero = {};
  numero["S"] = "Singular";
  numero["P"] = "Plural";
  numero["N"] = "Invariable";
  numero["0"] = "";

  const persona = {};
  persona["1"] = "Primera Persona";
  persona["2"] = "Segunda Persona";
  persona["3"] = "Tercera Persona";
  persona["0"] = "";

  const poseedor = {};
  poseedor["S"] = "Singular";
  poseedor["P"] = "Plurar";
  poseedor["0"] = "Indefinido";

  const tipo_A = {};
  tipo_A["0"] = "";
  tipo_A["O"] = "Ordinal";
  tipo_A["Q"] = "Calificativo";

  const funcion_A = {};
  funcion_A["0"] = "";
  funcion_A["P"] = "Participio";

  const tipo_R = {};
  tipo_R["G"] = "General";
  tipo_R["N"] = "Negativo";
  tipo_R["0"] = "";

  const tipo_D = {};
  tipo_D["D"] = "Demostrativo";
  tipo_D["P"] = "Posesivo";
  tipo_D["T"] = "Interrogativo";
  tipo_D["E"] = "Exclamativo";
  tipo_D["I"] = "Indefinido";
  tipo_D["A"] = "Artículo";
  tipo_D["0"] = "";

  const tipo_N = {};
  tipo_N["C"] = "Común";
  tipo_N["P"] = "Propio";
  tipo_N["0"] = "";

  const clasificacion_N1 = {};
  clasificacion_N1["S"] = "Persona";
  clasificacion_N1["G"] = "Lugar";
  clasificacion_N1["O"] = "Organización";
  clasificacion_N1["V"] = "Otros";
  clasificacion_N1["0"] = "";

  const clasificacion_N2 = {};
  clasificacion_N2["P"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";
  clasificacion_N2["0"] = "";

  const tipo_V = {};
  tipo_V["M"] = "Principal";
  tipo_V["A"] = "Auxiliar";
  tipo_V["S"] = "Semiauxiliar";
  tipo_V["0"] = "";

  const modo_V = {};
  modo_V["I"] = "Indicativo";
  modo_V["S"] = "Subjuntivo";
  modo_V["M"] = "Imperativo";
  modo_V["N"] = "Infinitivo";
  modo_V["G"] = "Gerundio";
  modo_V["P"] = "Participio";
  modo_V["0"] = "";

  const tiempo_V = {};
  tiempo_V["P"] = "Presente";
  tiempo_V["I"] = "Imperfecto";
  tiempo_V["F"] = "Futuro";
  tiempo_V["S"] = "Pasado";
  tiempo_V["C"] = "Condicional";
  tiempo_V["0"] = "";

  const tipo_P = {};
  tipo_P["P"] = "Personal";
  tipo_P["D"] = "Demostrativo";
  tipo_P["X"] = "Posesivo";
  tipo_P["I"] = "Indefinido";
  tipo_P["T"] = "Interrogativo";
  tipo_P["R"] = "Relativo";
  tipo_P["E"] = "Exclamativo";
  tipo_P["0"] = "";

  const caso_P = {};
  caso_P["N"] = "Nominativo";
  caso_P["A"] = "Acusativo";
  caso_P["D"] = "Dativo";
  caso_P["O"] = "Oblicuo";
  caso_P["0"] = "";

  const educado_P = {};
  educado_P["P"] = "Educado";
  educado_P["0"] = "";

  const tipo_C = {};
  tipo_C["C"] = "Coordinada";
  tipo_C["S"] = "Subordinada";
  tipo_C["0"] = "";

  const tipo_S = {};
  tipo_S["P"] = "Preposición";

  const forma_S = {};
  forma_S["S"] = "Simple";
  forma_S["C"] = "Contraída";
  forma_S["0"] = "";

  const tipo_Z = {};
  tipo_Z["d"] = "Partitivo";
  tipo_Z["m"] = "Moneda";
  tipo_Z["p"] = "Porcentaje";
  tipo_Z["u"] = "Unidad";
  tipo_Z["0"] = "";

  const tipo_F = {};

  tipo_F["aa"] = "";
  tipo_F["at"] = "";
  tipo_F["c"] = "";
  tipo_F["ca"] = "";
  tipo_F["ct"] = "";
  tipo_F["d"] = "";
  tipo_F["e"] = "";
  tipo_F["g"] = "";
  tipo_F["h"] = "";
  tipo_F["ia"] = "";
  tipo_F["it"] = "";
  tipo_F["la"] = "";
  tipo_F["lt"] = "";
  tipo_F["p"] = "";
  tipo_F["pa"] = "";
  tipo_F["pt"] = "";
  tipo_F["ra"] = "";
  tipo_F["rc"] = "";
  tipo_F["s"] = "";
  tipo_F["t"] = "";
  tipo_F["x"] = "";
  tipo_F["0"] = "";

  const posiciones = {};
  posiciones["A"] = [
    "categorias",
    "tipo_A",
    "grado",
    "genero",
    "numero",
    "funcion_A",
  ];
  posiciones["R"] = ["categorias", "tipo_R"];
  posiciones["D"] = [
    "categorias",
    "tipo_D",
    "persona",
    "genero",
    "numero",
    "poseedor",
  ];
  posiciones["N"] = [
    "categorias",
    "tipo_N",
    "genero",
    "numero",
    "clasificacion_N1",
    "clasificacion_N2",
    "grado",
  ];
  posiciones["V"] = [
    "categorias",
    "tipo_V",
    "modo_V",
    "tiempo_V",
    "persona",
    "numero",
    "genero",
  ];
  posiciones["P"] = [
    "categorias",
    "tipo_P",
    "persona",
    "genero",
    "numero",
    "caso_P",
    "poseedor",
    "educado_P",
  ];
  posiciones["C"] = ["categorias", "tipo_C"];
  posiciones["I"] = ["categorias"];
  posiciones["S"] = ["categorias", "tipo_S", "forma_S", "genero", "numero"];
  posiciones["F"] = ["categorias", "tipo_F"];
  posiciones["Z"] = ["categorias", "tipo_Z"];
  posiciones["W"] = ["categorias"];

  const atributos = {};
  atributos["categorias"] = categorias;
  atributos["genero"] = genero;
  atributos["grado"] = grado;
  atributos["numero"] = numero;
  atributos["persona"] = persona;
  atributos["poseedor"] = poseedor;
  atributos["tipo_A"] = tipo_A;
  atributos["funcion_A"] = funcion_A;
  atributos["tipo_R"] = tipo_R;
  atributos["tipo_D"] = tipo_D;
  atributos["tipo_N"] = tipo_N;
  atributos["clasificacion_N1"] = clasificacion_N1;
  atributos["clasificacion_N2"] = clasificacion_N2;
  atributos["tipo_V"] = tipo_V;
  atributos["modo_V"] = modo_V;
  atributos["tiempo_V"] = tiempo_V;
  atributos["tipo_P"] = tipo_P;

  atributos["caso_P"] = caso_P;
  atributos["educado_P"] = educado_P;
  atributos["tipo_C"] = tipo_C;
  atributos["tipo_S"] = tipo_S;
  atributos["forma_S"] = forma_S;
  atributos["tipo_Z"] = tipo_Z;
  atributos["tipo_F"] = tipo_F;

  const letra_cat = codigo.charAt(0);
  const human_readable_array = [];
  try {
    for (let i = 0; i < codigo.length; i++) {
      const _categoria = posiciones[letra_cat][i];
      let _code = codigo.charAt(i);

      if (i > 0 && letra_cat === "F" && codigo.length > 2) {
        _code += codigo.charAt(i + 1);
        i = codigo.length;
      }

      human_readable_array[i] = atributos[_categoria][_code];
    }
  }
  catch(error) {
    console.error(error);
    return codigo;
  }
  return human_readable_array.join(" ");
}
function freeling_code_to_readable_en(code) {
  const category = {}; // categoria
  category["CC"] = "Coordinating conjunction";
  category["CD"] = "Cardinal number";
  category["DT"] = "Determiner";
  category["EX"] = "Existential there";
  category["FW"] = "Foreing Word";
  category["IN"] = "Preposition or subordinating conjunction";
  category["JJ"] = "Adjective";
  category["JJR"] = "Adjective, comparative";
  category["JJS"] = "Adjective, superlative";
  category["LS"] = "List item marker";
  category["MD"] = "Modal";
  category["NN"] = "Noun, singular or mass";
  category["NNS"] = "Noun, plural";
  category["NP"] = "Proper noun, singular";
  category["NPS"] = "Proper noun, plural";
  category["PDT"] = "Predeterminer";
  category["POS"] = "Possessive ending";
  category["PP"] = "Personal pronoum";
  category["PP$"] = "Possessive pronoum";
  category["PRP"] = "Pronoun";
  category["PRP$"] = "Possesive pronoun or determiner";
  category["RB"] = "Adverb";
  category["RBR"] = "Adverb, comparative";
  category["RMS"] = "Adverb, superlative";
  category["RP"] = "Particle";
  category["SYM"] = "Symbol";
  category["TO"] = "Preposition";
  category["UH"] = "Interjection";
  category["VB"] = "Verb, base form";
  category["VBD"] = "Verb, past tense";
  category["VBG"] = "Verb, gerund or present participle";
  category["VBN"] = "Verb, past participle";
  category["VBP"] = "Verb, non -3rd person singular present";
  category["VBZ"] = "Verb, 3rd person singular present";
  category["WDT"] = "Relative pronoun";
  category["WP"] = "Relative pronoun";
  category["WP$"] = "Possessive Wh-pronoun";
  category["WRB"] = "Relative adverb";
  category["Z"] = "Number";
  category["Fp"] = "Punctuation";
  category["Fc"] = "Punctuation";
  category["W"] = "Date";

  return category[code];
}

export function code_to_readable(value, lang) {
  switch (lang) {
    case "es":
      return freeling_code_to_readable_es(value);
    case "en":
      return freeling_code_to_readable_en(value);
    case "gl":
      return freeling_code_to_readable_gl(value);
    case "pt":
      return freeling_code_to_readable_pt(value);
    default:
      return value;
  }
}
