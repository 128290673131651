import React from "react";
import styles from "./SideBarMenu.module.scss";
import LKTypography from "../../../reusable_components/LKTypography/LKTypography";

const SideBarMenu = ({ header, children }) => {
  return (
    <div className={styles.tools}>
      <LKTypography variant="h3">{header}</LKTypography>
      <hr className={styles.hr} />
      {children}
    </div>
  );
};

export default SideBarMenu;
