import React, { createContext, useReducer } from "react";
import i18next from "i18next";
import { en } from "../utils/languages";

export const AppContext = createContext();

const initialState = {
  language: en.code,
};

function appReducer(state, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE": {
      const newLanguage = action?.value?.language || en.code;
      i18next.changeLanguage(newLanguage);
      return { language: newLanguage };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
