import React, { useMemo } from "react";
import { shuffle } from "../../utils/extra_functions";
import styles from "./LKTagCloud.module.scss";

const fontSize = (weight, maxWeight) => {
  if (weight <= maxWeight / 4) {
    return "20px";
  }
  if (weight <= maxWeight / 2) {
    return `${Math.log(weight) * 5 + 20}px`;
  }

  return `${Math.log(weight) * 15 + 20}px`;
};

const LKTagCloud = ({ tags, maxTags }) => {
  const tagsElements = useMemo(() => {
    if (maxTags) {
      return tags.slice(0, maxTags);
    }
    return tags;
  }, [tags, maxTags]);

  const cloudData = useMemo(() => {
    return tags.reduce(
      (acc, current) => {
        if (acc.maxWeight < current.weight) {
          acc.maxWeight = current.weight;
        }
        return acc;
      },
      { maxWeight: 0 }
    );
  }, [tags]);

  return (
    <ul className={styles.tags}>
      {shuffle(tagsElements).map((item, index) => {
        return (
          <li
            className={`${styles.tag} ${
              item.weight <= cloudData.maxWeight / 2
                ? styles.colorBase
                : styles.colorAccent
            }`}
            key={`${item.value}${index}`}
            style={{ fontSize: fontSize(item.weight, cloudData.maxWeight) }}
          >
            {`${item.value}`}
          </li>
        );
      })}
    </ul>
  );
};

export default LKTagCloud;
