import React, { useCallback } from "react";
import Dropdown from "react-dropdown";
import "./style.css";
// import styles from "./LKDropdown.module.scss";

const LKDropdown = ({ options, value, onChange, placeholder, ...props }) => {
  const onHandleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <Dropdown
      {...props}
      options={options}
      onChange={onHandleChange}
      value={value}
      placeholder={placeholder}
    />
  );
};

export default LKDropdown;
