import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import global_es from "./assets/location/es/global.json";
import global_en from "./assets/location/en/global.json";
import global_gal from "./assets/location/gal/global.json";
import global_pt from "./assets/location/pt/global.json";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
  es: {
    translation: global_es,
  },
  en: {
    translation: global_en,
  },
  gl: {
    translation: global_gal,
  },
  pt: {
    translation: global_pt,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false,
});

export default i18n;
