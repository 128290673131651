import React from "react";
import LKTypography from "../../reusable_components/LKTypography/LKTypography";
import LKParagraph from "../../reusable_components/LKParagraph/LKParagraph";
import { useTranslation } from "react-i18next";
import styles from "./LegalPage.module.scss";

const LegalPage = () => {
  const [t] = useTranslation();
  return (
    <div className={styles.container}>
      <LKParagraph
        headerText={t("LEGAL_HEADER_LABEL")}
        bodyText={t("LEGAL_INTRO")}
        variant="main"
      />

      <LKTypography variant="h1">{t("LEGAL_HEADER_TERMS_LABEL")}</LKTypography>
      <div className={styles.termsContainer}>
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_FIRST_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_FIRST_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_SECOND_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_SECOND_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_THIRD_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_THIRD_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_FOURTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_FOURTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_FIFTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_FIFTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_SIXTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_SIXTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_SEVENTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_SEVENTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_EIGHTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_EIGHTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_NINETH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_NINETH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_TENTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_TENTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_TERMS_TEXT_ELEVENTH_HEADER")}
          bodyText={t("LEGAL_TERMS_TEXT_ELEVENTH_TEXT")}
          variant="base"
        />
      </div>
      <LKTypography variant="h1">
        {t("LEGAL_HEADER_PROTECT_LABEL")}
      </LKTypography>
      <div className={styles.protectContainer}>
        <LKParagraph
          headerText={t("LEGAL_PROTECT_TEXT_FIRST_HEADER")}
          bodyText={t("LEGAL_PROTECT_TEXT_FIRST_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_PROTECT_TEXT_SECOND_HEADER")}
          bodyText={t("LEGAL_PROTECT_TEXT_SECOND_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_PROTECT_TEXT_THIRD_HEADER")}
          bodyText={t("LEGAL_PROTECT_TEXT_THIRD_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_PROTECT_TEXT_FOURTH_HEADER")}
          bodyText={t("LEGAL_PROTECT_TEXT_FOURTH_TEXT")}
          variant="base"
        />
        <LKParagraph
          headerText={t("LEGAL_PROTECT_TEXT_FIFTH_HEADER")}
          bodyText={t("LEGAL_PROTECT_TEXT_FIFTH_TEXT")}
          variant="base"
        />
      </div>
    </div>
  );
};

export default LegalPage;
