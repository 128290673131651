import React from "react";
import styles from "./SideBarMenuElement.module.scss";
import { Link } from "react-router-dom";

const SideBarMenuElement = ({ to, image, imgAlt, text, highlighted }) => {
  return (
    <Link
      to={to}
      className={`${styles.item} ${highlighted ? styles.itemSelected : ""}`}
    >
      <img src={image} alt={imgAlt} className={styles.img}></img>
      {text}
    </Link>
  );
};

export default SideBarMenuElement;
