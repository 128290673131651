import React from "react";
import styles from "./LKWordCounter.module.scss";

const LKWordCounter = ({ characters, maxCharacters, percent }) => {
  const percentValue = (characters * 100) / maxCharacters;
  return (
    <div className={styles.container}>
      <div
        className={`${styles.filler} ${
          percentValue > 100 ? styles.fillerFull : ""
        }`}
        style={{
          width: `${percentValue}%`,
        }}
      >
        <span className={styles.label}>
          {percent ? `${percentValue}%` : `${characters}/${maxCharacters}`}
        </span>
      </div>
    </div>
  );
};

export default LKWordCounter;
