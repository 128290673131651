import React, { useCallback } from "react";
import styles from "./LKButton.module.scss";

const LKButton = ({ text, onClick, ...props }) => {
  const onHandleClick = useCallback(
    (e) => {
      onClick();
    },
    [onClick]
  );

  return (
    <button {...props} className={styles.button} onClick={onHandleClick}>
      {text}
    </button>
  );
};

export default LKButton;
