import React, { useCallback, useState } from "react";
import styles from "./LKTextArea.module.scss";

const LKTextArea = ({ placeholder, initialValue, onChange, ...props }) => {
  const [value, setValue] = useState(initialValue);

  const onHandleChange = useCallback(
    (e) => {
      setValue(e.target.value);
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange]
  );

  return (
    <textarea
      {...props}
      rows={5}
      className={styles.textarea}
      onChange={onHandleChange}
      placeholder={placeholder}
    >
      {value}
    </textarea>
  );
};

export default LKTextArea;
