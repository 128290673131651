import * as React from "react";

function LKKeyExtractorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={330}
      height={250}
      viewBox="0 0 330 250"
      {...props}
    >
      <g fill={props.fillValue || "#444"}>
        <path d="M96.045 40.524l29.032 29.032H96.045V40.524zM128.8 214.429H28.27V36.566h60.043v40.723H128.8v137.14zm7.733-145.014L96.045 28.927v-.094H20.532v193.333h116.001V69.415zM82.456 51.684H36.059v3.869h46.396v-3.869zm0 11.601H36.059v3.864h46.396v-3.864zm0 11.597H36.059v3.869h46.396v-3.869zm38.668 11.601H36.059v3.869h85.065v-3.869zm0 11.602H36.059v3.864h85.065v-3.864zm0 11.602H36.059v3.864h85.065v-3.864zm0 11.596H36.059v3.869h85.065v-3.869zm0 11.597H36.059v3.874h85.065v-3.874zm0 11.602H36.059v3.864h85.065v-3.864zm0 11.601H36.059v3.864h85.065v-3.864zm0 11.602H36.059v3.864h85.065v-3.864zm0 11.601H36.059v3.864h85.065v-3.864zm0 11.602H36.059v3.864h85.065v-3.864zM270.049 40.524l29.022 29.032h-29.022V40.524zm32.755 173.905h-100.54V36.566h60.047v40.723h40.493v137.14zm7.728-145.014l-40.483-40.488v-.094h-75.513v193.333h115.996V69.415zm-73.407-6.13h-19.339v3.864h19.339v-3.864zm19.33 11.597h-19.33v3.869h19.33v-3.869zm-30.932 23.203h-15.465v3.864h15.465v-3.864zm38.669 34.795h-23.203v3.874h23.203v-3.874zm11.592 11.602h-19.33v3.864h19.33v-3.864zm-36.732 23.203H227.45v3.864h11.602v-3.864zm56.071 11.601h-19.339v3.864h19.339v-3.864zM152.779 102.607v10.938l17.679 17.68-17.679 17.685v10.943l28.623-28.628-28.623-28.618z" />
      </g>
    </svg>
  );
}

export default LKKeyExtractorIcon;
