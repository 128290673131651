import React, { useEffect } from "react";
import styles from "./LKAdsense.module.scss";

const positions = {
  top: {
    slot: "8186235338",
  },
  bottom: {
    slot: "7544274255",
  },
  lateral: {
    slot: "4418558328",
  },
};

const LKAdsense = ({ position }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className={styles.adContainer}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7439566061186284"
        data-ad-slot={positions[position || "top"].slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default LKAdsense;
