import React from "react";
import LKTypography from "../../reusable_components/LKTypography/LKTypography";
import LKParagraph from "../../reusable_components/LKParagraph/LKParagraph";
import { useTranslation } from "react-i18next";
import bannerImage from "../../assets/img/linguakit_banner.png";
import styles from "./AboutPage.module.scss";

const AboutPage = () => {
  const [t] = useTranslation();
  return (
    <div className={styles.container}>
      <LKTypography variant="h1">{t("HEADERNAV_NAV_ABOUT")}</LKTypography>
      <img className={styles.bannerImage} src={bannerImage} alt="banner" />
      <LKParagraph
        headerText={t("Linguakit")}
        bodyText={t("ABOUT_FIRST_PARAGRAPH")}
        variant="main"
      />
      <LKParagraph
        headerText={t("ABOUT_CINELIS_PARAGRAPH_TITLE")}
        bodyText={t("ABOUT_CINELIS_PARAGRAPH")}
        variant="main"
      />
      <LKParagraph
        headerText={t("ABOUT_ROOTS_PARAGRAPH_TITLE")}
        bodyText={t("ABOUT_ROOTS_PARAGRAPH")}
        variant="main"
      />
      <LKTypography variant="h1">{t("ABOUT_OUR_PROPOSALS")}</LKTypography>
      <div className={styles.servicesDescriptionContainer}>
        <div className={styles.servicesDescriptionColumnContainer}>
          <LKParagraph
            headerText={t("MODULE_FULL_LABEL")}
            bodyText={t("ABOUT_FULL_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_SUMM_LABEL")}
            bodyText={t("ABOUT_SUMM_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("ABOUT_CORRECTOR_LABEL")}
            bodyText={t("ABOUT_CORRECTOR_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_CONJ_LABEL")}
            bodyText={t("ABOUT_CONJ_TEXT")}
            variant="base"
          />
        </div>

        <div className={styles.servicesDescriptionColumnContainer}>
          <LKParagraph
            headerText={t("MODULE_WRFR_LABEL")}
            bodyText={t("ABOUT_WORD_FREQUENCY_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_FULL_RESULT_KWIC")}
            bodyText={t("ABOUT_KWIC_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_POST_LABEL")}
            bodyText={t("ABOUT_MORPHOSYNTACTIC_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_DEPP_LABEL")}
            bodyText={t("ABOUT_DEPP_TEXT")}
            variant="base"
          />
        </div>
        <div className={styles.servicesDescriptionColumnContainer}>
          <LKParagraph
            headerText={t("MODULE_SENT_LABEL")}
            bodyText={t("ABOUT_SENT_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_LANG_LABEL")}
            bodyText={t("ABOUT_LANG_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_KEYW_LABEL")}
            bodyText={t("ABOUT_KEYW_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_MULT_LABEL")}
            bodyText={t("ABOUT_MULT_TEXT")}
            variant="base"
          />

          <LKParagraph
            headerText={t("MODULE_NER_LABEL")}
            bodyText={t("ABOUT_NER_TEXT")}
            variant="base"
          />
        </div>
        <div className={styles.servicesDescriptionColumnContainer}>
          <LKParagraph
            headerText={t("MODULE_AVAL_LABEL")}
            bodyText={t("ABOUT_AVAL_TEXT")}
            variant="base"
          />
          <LKParagraph
            headerText={t("MODULE_TRIP_LABEL")}
            bodyText={t("ABOUT_TRIP_TEXT")}
            variant="base"
          />
        </div>
        <LKParagraph
          headerText={t("ABOUT_HOW_WORKS_LABEL")}
          bodyText={t("ABOUT_HOW_WORKS_TEXT")}
          variant="main"
        />
        <LKParagraph
          headerText={t("ABOUT_OPTIONS_LABEL")}
          bodyText={t("ABOUT_OPTIONS_TEXT")}
          variant="main"
        />
      </div>
    </div>
  );
};

export default AboutPage;
