import React, { useState, useCallback, useContext } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import CommonServices from "../../../../services/CommonServices";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import SentimentIcon from "../../../../reusable_components/LKIcons/LKSentimentIcon";
import styles from "./SentimentAnalyzerPage.module.scss";
import { parseSentimentResult } from "../utils/pageParsers";
const SentimentAnalyzerPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);

  const headerResult = [
    t("MODULE_SENT_RESULT_SENTIMENT"),
    t("MODULE_SENT_RESULT_STATS"),
  ];
  const onSubmitClick = useCallback(async () => {
    const response = await CommonServices.sentimentAnalizer({
      lang: state.language,
      text,
    });
    setResult(response);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_SENT_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_SENT_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result !== "" && (
        <LKResultContainer Icon={SentimentIcon} title={t("MODULE_SENT_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTable
              headers={headerResult}
              values={parseSentimentResult(result)}
            />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default SentimentAnalyzerPage;
