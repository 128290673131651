import React, { useState, useCallback, useContext, useMemo } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import MultiWordIcon from "../../../../reusable_components/LKIcons/LKMultiWordIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import CommonServices from "../../../../services/CommonServices";
import LKTagCloud from "../../../../reusable_components/LKTagCloud/LKTagCloud";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import styles from "./MultiWordExtractorPage.module.scss";
import { parseMultiWordResult } from "../utils/pageParsers";

const MultiWordExtractorPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);
  const headerResult = [
    t("MODULE_MULT_RESULT_WORD"),
    t("MODULE_MULT_RESULT_WEIGHT"),
    t("MODULE_MULT_RESULT_TAG"),
  ];
  const multikeywordResult = useMemo(() => {
    if (result !== "") {
      return parseMultiWordResult(result, state.language, 100);
    }
    return undefined;
  }, [result]);

  const onSubmitClick = useCallback(async () => {
    const response = await CommonServices.multiWordExtractor({
      lang: state.language,
      text,
    });
    setResult(response);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_MULT_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_MULT_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result !== "" && (
        <LKResultContainer Icon={MultiWordIcon} title={t("MODULE_MULT_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTagCloud
              tags={multikeywordResult.map((item) => {
                return { value: item[0], weight: item[1], type: item[2] };
              })}
              maxTags={25}
            />
            <LKTable headers={headerResult} values={multikeywordResult} />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default MultiWordExtractorPage;
