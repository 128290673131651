import React, { useState, useContext, useCallback } from "react";
import styles from "./SyntacticAnalyzer.module.scss";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import SyntacticAnalyzerIcon from "../../../../reusable_components/LKIcons/LKSyntacticAnalizerIcon";
import { useTranslation } from "react-i18next";
import CommonServices from "../../../../services/CommonServices";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import { AppContext } from "../../../../context/appContext";
import { parseSyntacticResult } from "../utils/pageParsers";

const SyntacticAnalyzer = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);

  const headerResult = [
    t("MODULE_DEPP_RESULT_DP"),
    t("MODULE_DEPP_RESULT_D"),
    t("MODULE_DEPP_RESULT_DC"),
    t("MODULE_DEPP_RESULT_HP"),
    t("MODULE_DEPP_RESULT_SR"),
  ];

  const onSubmitClick = useCallback(async () => {
    const response = await CommonServices.syntacticAnalizer({
      lang: state.language,
      text,
    });
    setResult(response);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_DEPP_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_DEPP_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea onChange={(value) => setText(value)} />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result !== "" && (
        <LKResultContainer
          Icon={SyntacticAnalyzerIcon}
          title={t("MODULE_DEPP_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTable
              headers={headerResult}
              values={parseSyntacticResult(result, state.language)}
            />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default SyntacticAnalyzer;
