import React from "react";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./context/appContext";
import { render } from "react-snapshot";

const rootElement = document.getElementById("root");
render(
  <React.StrictMode>
    <AppContextProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </AppContextProvider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
