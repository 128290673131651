import React, { useState, useCallback, useContext, useMemo } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import KeyWordIcon from "../../../../reusable_components/LKIcons/LKKeyExtractorIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import LKTagCloud from "../../../../reusable_components/LKTagCloud/LKTagCloud"; // Revisar se este importe é necesario
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense"; // Revisar se este importe é necesario
import CommonServices from "../../../../services/CommonServices";
import styles from "./KeyWordExtractorPage.module.scss";
import { parseKeyWordResult } from "../utils/pageParsers";

const KeyWordExtractorPage = () => {
    const [t] = useTranslation();
    const [text, setText] = useState("");
    const [result, setResult] = useState("");
    const { state } = useContext(AppContext);
    const keywordResult = useMemo(() => {
        if (result !== "") {
            return parseKeyWordResult(result, state.language, 100);
        }
        return undefined;
    }, [result]);

    const headerResult = [
        t("MODULE_KEYW_RESULT_WORD"),
        t("MODULE_KEYW_RESULT_WEIGHT"),
        t("MODULE_KEYW_RESULT_TAG"),
    ];
    const onSubmitClick = useCallback(async() => {
        const response = await CommonServices.keyWordExtractor({
            lang: state.language,
            text,
        });
        setResult(response);
    }, [text, state.language]);

    return ( <
        div className = { styles.container } >
        <
        LKTypography variant = "h2" > { t("MODULE_KEYW_LABEL") } < /LKTypography> <
        LKTypography variant = "base" > { t("MODULE_KEYW_DESCRIPTION") } < /LKTypography> <
        LKWordCounter characters = { text.length }
        maxCharacters = { 5000 }
        /> <
        LKTextArea onChange = {
            (value) => {
                setText(value);
            }
        }
        /> <
        div className = { styles.buttonsContainer } >
        <
        LKButton text = { t("INPUT_ANALYZE_BTN") }
        onClick = { onSubmitClick }
        /> <
        /div> <
        LKAdsense position = "bottom" / > {
            result !== "" && ( <
                LKResultContainer Icon = { KeyWordIcon }
                title = { t("MODULE_KEYW_LABEL") } >
                <
                div className = { styles.tableContainer } >
                <
                LKTagCloud tags = {
                    keywordResult.map((item) => {
                        return { value: item[0], weight: item[1], type: item[2] };
                    })
                }
                maxTags = { 25 }
                /> <
                LKTable headers = { headerResult }
                values = { keywordResult }
                /> <
                /div> <
                /LKResultContainer>
            )
        } <
        /div>
    );
};

export default KeyWordExtractorPage;