import React, { useState, useCallback, useContext } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import CommonServices from "../../../../services/CommonServices";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import MorphoSyntacticIcon from "../../../../reusable_components/LKIcons/LKMorphoSyntacticIcon";
import styles from "./MorphosyntacticTaggerPage.module.scss";
import { parseMorphosyntacticResult } from "../utils/pageParsers";

const MorphosyntacticTaggerPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);
  const onSubmitClick = useCallback(async () => {
    const response = await CommonServices.wordFrequency({
      lang: state.language,
      text,
    });
    setResult(response);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_POST_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_POST_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result !== "" && (
        <LKResultContainer
          Icon={MorphoSyntacticIcon}
          title={t("MODULE_POST_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTable
              headers={[
                t("MODULE_POST_RESULT_WORD"),
                t("MODULE_POST_RESULT_LEMMA"),
                t("MODULE_POST_RESULT_TAG"),
              ]}
              values={parseMorphosyntacticResult(result, state.language)}
            />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default MorphosyntacticTaggerPage;
