import React, { useContext, useEffect } from "react";
import styles from "./Header.module.scss";
import logo from "../../assets/img/logo.png";
import iconHamburguer from "../../assets/icons/iconHamburguer.svg";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import MenuButton from "./MenuButton";
import routes from "../../utils/routes";
import useWindowSize from "../../hooks/useWindowSize";
import LanguageSelector from "./LanguageSelector";

const Header = ({ menuClick, menuButtonSelected }) => {
  const { state, dispatch } = useContext(AppContext);
  const { pathname } = useLocation();
  const { isSmall } = useWindowSize();
  const paths = pathname.split("/");
  useEffect(() => {
    if (paths[1]) {
      dispatch({ type: "CHANGE_LANGUAGE", value: { language: paths[1] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <nav className={styles.navigationBar}>
      {isSmall && (
        <div
          className={`${styles.iconMenu} ${
            menuButtonSelected ? styles.iconMenuSelected : ""
          }`}
          onClick={menuClick ? () => menuClick() : () => {}}
        >
          <img src={iconHamburguer} alt="Menu" />
        </div>
      )}
      <div className={styles.logo}>
        <img src={logo} alt="logo linguakit" />
      </div>

      <div className={styles.menuBig}>
        <div className={styles.links}>
          {/* <MenuButton name="Planes" to="#" highlighted /> */}
          <MenuButton
            name="Sobre Linguakit"
            to={`/${paths[1] || state.language}${
              routes.ABOUT_PAGE[state.language]
            }`}
            highlighted={
              paths[2] && routes.ABOUT_PAGE[state.language].includes(paths[2])
            }
          />
          <MenuButton
            name="Legal"
            to={`/${paths[1] || state.language}${
              routes.LEGAL_PAGE[state.language]
            }`}
            highlighted={
              paths[2] && routes.LEGAL_PAGE[state.language].includes(paths[2])
            }
          />
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
};

export default Header;
