import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import analysis from "../../assets/icons/modules/icon_full.svg";
import resumidor from "../../assets/icons/modules/icon_resume.svg";
import morphosyntactic from "../../assets/icons/modules/icon_morphosynt.svg";
import syntactic from "../../assets/icons/modules/icon_anal_synta.svg";
import sentiment from "../../assets/icons/modules/icon_sentiment.svg";
import keyword from "../../assets/icons/modules/icon_keyextractor.svg";
import multiword from "../../assets/icons/modules/icon_multiword.svg";
import triplets from "../../assets/icons/modules/icon_triplet.svg";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/appContext";
import SideBarMenu from "./SideBarMenu/SideBarMenu";
import SideBarMenuElement from "./SideBarMenu/SideBarMenuElement";
import routes from "../../utils/routes";
import LKAdsense from "../../reusable_components/LKAdsense/LKAdsense";

const Sidebar = () => {
  const [t] = useTranslation();
  const { state } = useContext(AppContext);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const paths = pathname.split("/");

  return (
    <div className={styles.sidebar}>
      <SideBarMenu
        header={`${t("SIDENAV_TOOLS_LEFT")} ${t("SIDENAV_TOOLS_RIGHT")}`}
      >
        <SideBarMenuElement
          to={`${url}${routes.ANALYSIS_PAGE[state.language]}`}
          image={analysis}
          altImg="analysis linguakit"
          text={t("MODULE_FULL_LABEL")}
          highlighted={
            paths[2] && routes.ANALYSIS_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        <SideBarMenuElement
          to={`${url}${routes.SUMMARY_PAGE[state.language]}`}
          image={resumidor}
          altImg="summarize linguakit"
          text={t("MODULE_SUMM_LABEL")}
          highlighted={
            paths[2] && routes.SUMMARY_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        {/* <SideBarMenuElement
          to={`${url}${routes.SUPERCORRECTOR_PAGE[state.language]}`}
          image={analysis}
          altImg="supercorrector linguakit"
          text={t("MODULE_AVAL_LABEL")}
          highlighted={
            paths[2] &&
            routes.SUPERCORRECTOR_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement> */}
        {/*
        <SideBarMenuElement
          to={`${url}${routes.CONJUGATOR_PAGE[state.language]}`}
          image={conjugador}
          altImg="conjugator linguakit"
          text={t("MODULE_CONJ_LABEL")}
          highlighted={
            paths[2] &&
            routes.CONJUGATOR_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        */}
      </SideBarMenu>
      <SideBarMenu
        header={`${t("SIDENAV_ANALYSIS_LEFT")} ${t("SIDENAV_ANALYSIS_RIGHT")}`}
      >
        {/*
        <SideBarMenuElement
          to={`${url}${routes.WORD_FREQUENCY_PAGE[state.language]}`}
          image={analysis}
          altImg="word frequency linguakit"
          text={t("MODULE_WRFR_LABEL")}
          highlighted={
            paths[2] &&
            routes.WORD_FREQUENCY_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        <SideBarMenuElement
          to={`${url}${routes.KEYWORD_PAGE[state.language]}`}
          image={resumidor}
          altImg="keyword linguakit"
          text={t("MODULE_FULL_RESULT_KWIC")}
          highlighted={
            paths[2] && routes.KEYWORD_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        */}
        <SideBarMenuElement
          to={`${url}${routes.MORPHOSYNTACTIC_TAGGER_PAGE[state.language]}`}
          image={morphosyntactic}
          altImg="morphosyntactic tagger linguakit"
          text={t("MODULE_POST_LABEL")}
          highlighted={
            paths[2] &&
            routes.MORPHOSYNTACTIC_TAGGER_PAGE[state.language].includes(
              paths[2]
            )
          }
        ></SideBarMenuElement>
        <SideBarMenuElement
          to={`${url}${routes.SYNTACTIC_ANALYZER_PAGE[state.language]}`}
          image={syntactic}
          altImg="syntactic analyzer linguakit"
          text={t("MODULE_DEPP_LABEL")}
          highlighted={
            paths[2] &&
            routes.SYNTACTIC_ANALYZER_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
      </SideBarMenu>

      <SideBarMenu
        header={`${t("SIDENAV_ANALYTICS_LEFT")} ${t(
          "SIDENAV_ANALYTICS_RIGHT"
        )}`}
      >
        <SideBarMenuElement
          to={`${url}${routes.SENTIMENT_ANALYZER_PAGE[state.language]}`}
          image={sentiment}
          altImg="sentiment analyzer linguakit"
          text={t("MODULE_SENT_LABEL")}
          highlighted={
            paths[2] &&
            routes.SENTIMENT_ANALYZER_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        {/*
        <SideBarMenuElement
          to={`${url}${routes.LANGUAGE_IDENTIFIER_PAGE[state.language]}`}
          image={resumidor}
          altImg="language identifier linguakit"
          text={t("MODULE_LANG_LABEL")}
          highlighted={
            paths[2] &&
            routes.LANGUAGE_IDENTIFIER_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        */}
        <SideBarMenuElement
          to={`${url}${routes.KEYWORD_EXTRACTOR_PAGE[state.language]}`}
          image={keyword}
          altImg="keyword extractor linguakit"
          text={t("MODULE_KEYW_LABEL")}
          highlighted={
            paths[2] &&
            routes.KEYWORD_EXTRACTOR_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        <SideBarMenuElement
          to={`${url}${routes.MULTIWORD_EXTRACTOR_PAGE[state.language]}`}
          image={multiword}
          altImg="multiword extractor linguakit"
          text={t("MODULE_MULT_LABEL")}
          highlighted={
            paths[2] &&
            routes.MULTIWORD_EXTRACTOR_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        {/*
        <SideBarMenuElement
          to={`${url}${routes.ENTITY_RECOGNIZER_PAGE[state.language]}`}
          image={resumidor}
          altImg="entity recognizer linguakit"
          text={t("MODULE_NER_LABEL")}
          highlighted={
            paths[2] &&
            routes.ENTITY_RECOGNIZER_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
        */}
        <SideBarMenuElement
          to={`${url}${routes.TRIPLET_EXTRACTOR_PAGE[state.language]}`}
          image={triplets}
          altImg="triplet extractor linguakit"
          text={t("MODULE_TRIP_LABEL")}
          highlighted={
            paths[2] &&
            routes.TRIPLET_EXTRACTOR_PAGE[state.language].includes(paths[2])
          }
        ></SideBarMenuElement>
      </SideBarMenu>
      <LKAdsense position="lateral" />
    </div>
  );
};

export default Sidebar;
