import React, { useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import MainPage from "./Components/MainPage/MainPage";
import ReactGA from "react-ga";
import RouteChangeTracker from "./utils/RouteChangeTracker";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-43151326-1"); //Careful, this should be protected
  }, []);

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Linguakit </title>{" "}
        <meta
          name="Linguakit"
          content="Linguakit is a word analizer which supplies lot of features to analize your text"
        />
      </Helmet>{" "}
      <Router>
        <Switch>
          <Route path={["/es", "/en", "/gl", "/pt"]} component={MainPage} />{" "}
          <Redirect from={"/"} to={"/es"} />{" "}
        </Switch>{" "}
        <RouteChangeTracker />
      </Router>{" "}
    </div>
  );
}

export default App;
