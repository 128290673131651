import React from "react";

function LKSummaryIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={330}
      height={250}
      viewBox="0 0 330 250"
      {...props}
    >
      <g fill={props.fillValue || "#444"}>
        <path d="M153.051 102.524v10.962l17.721 17.721-17.721 17.721V159.9l28.684-28.693-28.684-28.683zM96.19 40.301l29.093 29.098H96.19V40.301zm32.83 174.305H28.252V36.331h60.183v40.818h40.584v137.457zm7.754-145.35L96.19 28.677v-.096H20.508v193.775h116.267v-153.1zm-15.452 17.106H36.064v3.88h85.258v-3.88zm0 11.625H36.064v3.879h85.258v-3.879zm0 11.629H36.064v3.875h85.258v-3.875zm0 11.625H36.064v3.88h85.258v-3.88zm0 11.625H36.064v3.88h85.258v-3.88zm0 11.63H36.064v3.87h85.258v-3.87zM80.838 51.835H36.064v3.875h44.774v-3.875zm0 11.625H36.064v3.879h44.774V63.46zm0 11.625H36.064v3.875h44.774v-3.875zm40.484 81.04H36.064v3.87h85.258v-3.87zm0 11.629H36.064v3.871h85.258v-3.871zm0 11.621H36.064v3.88h85.258v-3.88zm0 11.629H36.064v3.87h85.258v-3.87zM269.918 40.301l29.093 29.098h-29.093V40.301zm32.83 174.305H201.99V36.331h60.178v40.818h40.58v137.457zm7.76-145.35l-40.589-40.579v-.096h-75.678v193.775h116.267v-153.1zm-15.452 17.106h-85.258v3.88h85.258v-3.88zm0 11.625h-85.258v3.879h85.258v-3.879zm0 11.629h-85.258v3.875h85.258v-3.875zm0 11.625h-85.258v3.88h85.258v-3.88zm-40.485-69.406h-44.774v3.875h44.774v-3.875zm0 11.625h-44.774v3.879h44.774V63.46zm0 11.625h-44.774v3.875h44.774v-3.875z" />
      </g>
    </svg>
  );
}

export default LKSummaryIcon;
