export function abbreviation_to_readable(abreviation, lang) {

    switch (abreviation) {

        case 'N':
            if (lang === 'es') {
                return 'nombre';
            } else if (lang === 'gl') {
                return 'nome';
            } else if (lang === 'pt') {
                return 'nome';
            } else if (lang === 'en') {
                return 'noun';
            }
        case 'NOUN':
            if (lang === 'es') {
                return 'nombre';
            } else if (lang === 'gl') {
                return 'nome';
            } else if (lang === 'pt') {
                return 'nome';
            } else if (lang === 'en') {
                return 'noun';
            }
        case 'A':
            if (lang === 'es') {
                return 'adjetivo';
            } else if (lang === 'gl') {
                return 'adxectivo';
            } else if (lang === 'pt') {
                return 'adjectivo';
            } else if (lang === 'en') {
                return 'adjective';
            }
        case 'V':
            if (lang === 'es') {
                return 'verbo';
            } else if (lang === 'gl') {
                return 'verbo';
            } else if (lang === 'pt') {
                return 'verbo';
            } else if (lang === 'en') {
                return 'verb';
            }
        case 'VERB':
            if (lang === 'es') {
                return 'verbo';
            } else if (lang === 'gl') {
                return 'verbo';
            } else if (lang === 'pt') {
                return 'verbo';
            } else if (lang === 'en') {
                return 'verb';
            }
        case 'P':
            if (lang === 'es') {
                return 'preposición';
            } else if (lang === 'gl') {
                return 'preposición';
            } else if (lang === 'pt') {
                return 'preposição';
            } else if (lang === 'en') {
                return 'preposition';
            }
        case 'PERS':
            if (lang === 'es') {
                return 'persona';
            } else if (lang === 'gl') {
                return 'persoa';
            } else if (lang === 'pt') {
                return 'pessoa';
            } else if (lang === 'en') {
                return 'person';
            }
        case 'LOCAL':
            if (lang === 'es') {
                return 'localidad';
            } else if (lang === 'gl') {
                return 'localidade';
            } else if (lang === 'pt') {
                return 'localidade';
            } else if (lang === 'en') {
                return 'locality';
            }
        case 'QUANT':
            if (lang === 'es') {
                return 'cuantificador';
            } else if (lang === 'gl') {
                return 'cuantificador';
            } else if (lang === 'pt') {
                return 'quantificador';
            } else if (lang === 'en') {
                return 'quantifier';
            }
        case 'DATE':
            if (lang === 'es') {
                return 'fecha';
            } else if (lang === 'gl') {
                return 'data';
            } else if (lang === 'pt') {
                return 'data';
            } else if (lang === 'en') {
                return 'date';
            }
        case 'ORG':
            if (lang === 'es') {
                return 'organización';
            } else if (lang === 'gl') {
                return 'organización';
            } else if (lang === 'pt') {
                return 'organização';
            } else if (lang === 'en') {
                return 'organization';
            }
        case 'MISC':
            if (lang === 'es') {
                return 'miscelánea';
            } else if (lang === 'gl') {
                return 'miscelánea';
            } else if (lang === 'pt') {
                return 'miscelânea';
            } else if (lang === 'en') {
                return 'miscellany';
            }
        case 'DET':
            if (lang === 'es') {
                return 'determinante';
            } else if (lang === 'gl') {
                return 'determinante';
            } else if (lang === 'pt') {
                return 'determinante';
            } else if (lang === 'en') {
                return 'determinante';
            }
        case 'ADJ':
            if (lang === 'es') {
                return 'adjetivo';
            } else if (lang === 'gl') {
                return 'adxectivo';
            } else if (lang === 'pt') {
                return 'adjectivo';
            } else if (lang === 'en') {
                return 'adjective';
            }
        case 'J':
            if (lang === 'es') {
                return 'adjetivo';
            } else if (lang === 'gl') {
                return 'adxectivo';
            } else if (lang === 'pt') {
                return 'adjectivo';
            } else if (lang === 'en') {
                return 'adjective';
            }
        case 'CONJ':
            if (lang === 'es') {
                return 'conjunción';
            } else if (lang === 'gl') {
                return 'conxunción';
            } else if (lang === 'pt') {
                return 'conjunção';
            } else if (lang === 'en') {
                return 'conjunction';
            }
        case 'PRO':
            if (lang === 'es') {
                return 'pronombre';
            } else if (lang === 'gl') {
                return 'pronome';
            } else if (lang === 'pt') {
                return 'pronome';
            } else if (lang === 'en') {
                return 'pronoun';
            }
        case 'PRP':
            if (lang === 'es') {
                return 'preposición';
            } else if (lang === 'gl') {
                return 'preposición';
            } else if (lang === 'pt') {
                return 'preposição';
            } else if (lang === 'en') {
                return 'preposition';
            }
        case 'ADV':
            if (lang === 'es') {
                return 'adverbio';
            } else if (lang === 'gl') {
                return 'adverbio';
            } else if (lang === 'pt') {
                return 'advérbio';
            } else if (lang === 'en') {
                return 'adverb';
            }
        case 'CARD':
            if (lang === 'es') {
                return 'cantidad';
            } else if (lang === 'gl') {
                return 'cantidade';
            } else if (lang === 'pt') {
                return 'quantidade';
            } else if (lang === 'en') {
                return 'quantity';
            }
        case 'ENTITY':
            if (lang === 'es') {
                return 'entidad';
            } else if (lang === 'gl') {
                return 'entidade';
            } else if (lang === 'pt') {
                return 'entidade';
            } else if (lang === 'en') {
                return 'entity';
            }
        case 'Fc':
            if (lang === 'es') {
                return 'puntuación';
            } else if (lang === 'gl') {
                return 'puntuación';
            } else if (lang === 'pt') {
                return 'puntuação';
            } else if (lang === 'en') {
                return 'puntuation';
            }
        case 'Fpa':
            if (lang === 'es') {
                return 'puntuación';
            } else if (lang === 'gl') {
                return 'puntuación';
            } else if (lang === 'pt') {
                return 'puntuação';
            } else if (lang === 'en') {
                return 'puntuation';
            }
        case 'Fpt':
            if (lang === 'es') {
                return 'puntuación';
            } else if (lang === 'gl') {
                return 'puntuación';
            } else if (lang === 'pt') {
                return 'puntuação';
            } else if (lang === 'en') {
                return 'puntuation';
            }
        case 'lex':
            if (lang === 'es') {
                return 'Léxico';
            } else if (lang === 'gl') {
                return 'Léxico';
            } else if (lang === 'pt') {
                return 'Lexical';
            } else if (lang === 'en') {
                return 'Lexical';
            }
        case 'gram':
            if (lang === 'es') {
                return 'Gramatical';
            } else if (lang === 'gl') {
                return 'Gramatical';
            } else if (lang === 'pt') {
                return 'Gramatical';
            } else if (lang === 'en') {
                return 'Grammar';
            }
        case 'SENT':
            if (lang === 'es') {
                return 'Puntuación fin de frase';
            } else if (lang === 'gl') {
                return 'Puntuación fin de frase';
            } else if (lang === 'pt') {
                return 'Puntuação fim de frase';
            } else if (lang === 'en') {
                return 'Puntuation end of sentence';
            }
        default :
            return abreviation;
    }
}