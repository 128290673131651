import React from "react";
import LKTypography from "../LKTypography/LKTypography";
import styles from "./LKParagraph.module.scss";

//variant = base | main
const LKParagraph = ({ headerText, bodyText, variant = "base" }) => {
  return (
    <div className={styles.container}>
      <LKTypography variant={variant === "main" ? "h2" : "h3"}>
        {headerText}
      </LKTypography>
      {bodyText.split("\n").map((text, index) => (
        <LKTypography key={index} variant="base">
          {text}
        </LKTypography>
      ))}
    </div>
  );
};

export default LKParagraph;
