import React, { useState, useCallback, useContext } from "react";
import styles from "./SummaryPage.module.scss";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import LKNumberSelector from "../../../../reusable_components/LKNumberSelector/LKNumberSelector";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import ResumidorIcon from "../../../../reusable_components/LKIcons/LKSummaryIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import summarize from "../../../../utils/summarize";

const SummaryPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [percent, setPercent] = useState(20);
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);

  const onSubmitClick = useCallback(async () => {
    const summary = summarize(text, percent, "pt");
    setResult(summary || "Not enough info");
  }, [text, state.language, percent]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_SUMM_LABEL")}</LKTypography>
      <LKTypography variant="base">
        {t(
          "Todos los análisis lingüísticos en un solo lugar. El análisis completo informa del número de palabras y frases del texto, y de su tipología. Ofrece un resumen y su valoración. Además, te facilita las cinco palabras y multipalabras más relevantes del texto, las entidades más importantes que allí se mencionan, las palabras más frecuentes y el contexto en el que aparece la palabra clave escogida."
        )}
      </LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.numberContainer}>
        <LKTypography variant="base">{t("INPUT_SUMMARIZE")}</LKTypography>
        <LKNumberSelector
          onChange={(value) => {
            setPercent(value);
          }}
          minValue={1}
          maxValue={99}
          initialValue={20}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result !== "" && (
        <LKResultContainer Icon={ResumidorIcon} title={t("MODULE_SUMM_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTypography variant="base">{result}</LKTypography>
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default SummaryPage;
