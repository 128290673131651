import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import SidebarReduced from "../Sidebar/SidebarReduced";
import styles from "./MainPage.module.scss";
import {
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  Switch,
} from "react-router-dom";
import AnalysisPage from "./ContainerPages/AnalysisPage/AnalysisPage";
import Header from "../Header/Header";
import HeaderReduced from "../Header/HeaderReduced";
import ConjugatorPage from "./ContainerPages/ConjugatorPage/ConjugatorPage";
import EntityRecognizerPage from "./ContainerPages/EntityRecognizerPage/EntityRecognizerPage";
import KeyWordExtractorPage from "./ContainerPages/KeyWordExtractorPage/KeyWordExtractorPage";
import KeyWordPage from "./ContainerPages/KeyWordPage/KeyWordPage";
import LanguageIdentifierPage from "./ContainerPages/LanguageIdentifierPage/LanguageIdentifierPage";
import MorphosyntacticTaggerPage from "./ContainerPages/MorphosyntacticTaggerPage/MorphosyntacticTaggerPage";
import MultiWordExtractorPage from "./ContainerPages/MultiWordExtractorPage/MultiWordExtractorPage";
import SentimentAnalyzerPage from "./ContainerPages/SentimentAnalyzerPage/SentimentAnalyzerPage";
import SummaryPage from "./ContainerPages/SummaryPage/SummaryPage";
import SuperCorrectorPage from "./ContainerPages/SuperCorrectorPage/SuperCorrectorPage";
import SyntacticAnalyzerPage from "./ContainerPages/SyntacticAnalyzerPage/SyntacticAnalyzerPage";
import TripletExtractorPage from "./ContainerPages/TripletExtractorPage/TripletExtractorPage";
import WordFrequencyPage from "./ContainerPages/WordFrequencyPage/WordFrequencyPage";
import AboutPage from "../AboutPage/AboutPage";
import LegalPage from "../LegalPage/LegalPage";
import LKAdsense from "../../reusable_components/LKAdsense/LKAdsense";
import { AppContext } from "../../context/appContext";
import routes from "../../utils/routes";
import useWindowSize from "../../hooks/useWindowSize";

const MainPage = (props) => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const { isSmall } = useWindowSize();
  const [sideBarOpen, setSideBarOpen] = useState(false);

  useEffect(() => {
    const paths = pathname.split("/");
    if (paths.length > 1 && state.language !== paths[1]) {
      dispatch({ type: "CHANGE_LANGUAGE", value: { language: paths[1] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.language]);

  return (
    <>
      {isSmall ? (
        <HeaderReduced
          menuClick={() => {
            setSideBarOpen(!sideBarOpen);
          }}
          menuButtonSelected={sideBarOpen}
        />
      ) : (
        <Header
          menuClick={() => {
            setSideBarOpen(!sideBarOpen);
          }}
          menuButtonSelected={sideBarOpen}
        />
      )}
      <div className={styles.container}>
        {isSmall ? (
          <SidebarReduced
            closeSidebar={() => setSideBarOpen(false)}
            isOpen={sideBarOpen}
          />
        ) : (
          <Sidebar />
        )}
        <div className={styles.rightContainer}>
          <LKAdsense position="top" />
          <Switch>
            <Route
              exact
              path={[`${url}${routes.ANALYSIS_PAGE[state.language]}`]}
            >
              <AnalysisPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.SUMMARY_PAGE[state.language]}`]}
            >
              <SummaryPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.CONJUGATOR_PAGE[state.language]}`]}
            >
              <ConjugatorPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.WORD_FREQUENCY_PAGE[state.language]}`]}
            >
              <WordFrequencyPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.KEYWORD_PAGE[state.language]}`]}
            >
              <KeyWordPage />
            </Route>
            <Route
              exact
              path={[
                `${url}${routes.MORPHOSYNTACTIC_TAGGER_PAGE[state.language]}`,
              ]}
            >
              <MorphosyntacticTaggerPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.SYNTACTIC_ANALYZER_PAGE[state.language]}`]}
            >
              <SyntacticAnalyzerPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.SENTIMENT_ANALYZER_PAGE[state.language]}`]}
            >
              <SentimentAnalyzerPage />
            </Route>
            <Route
              exact
              path={[
                `${url}${routes.LANGUAGE_IDENTIFIER_PAGE[state.language]}`,
              ]}
            >
              <LanguageIdentifierPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.KEYWORD_EXTRACTOR_PAGE[state.language]}`]}
            >
              <KeyWordExtractorPage />
            </Route>
            <Route
              exact
              path={[
                `${url}${routes.MULTIWORD_EXTRACTOR_PAGE[state.language]}`,
              ]}
            >
              <MultiWordExtractorPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.ENTITY_RECOGNIZER_PAGE[state.language]}`]}
            >
              <EntityRecognizerPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.SUPERCORRECTOR_PAGE[state.language]}`]}
            >
              <SuperCorrectorPage />
            </Route>
            <Route
              exact
              path={[`${url}${routes.TRIPLET_EXTRACTOR_PAGE[state.language]}`]}
            >
              <TripletExtractorPage />
            </Route>
            {/* This 2 should change, just for now they will be here*/}
            <Route exact path={[`${url}${routes.ABOUT_PAGE[state.language]}`]}>
              <AboutPage />
            </Route>
            <Route exact path={[`${url}${routes.LEGAL_PAGE[state.language]}`]}>
              <LegalPage />
            </Route>

            {/* This could be bad, care*/}
            <Redirect to={`${url}${routes.ANALYSIS_PAGE[state.language]}`} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default MainPage;
