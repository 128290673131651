import React, { useState, useCallback, useContext } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import SummaryIcon from "../../../../reusable_components/LKIcons/LKSummaryIcon";
import MorphoSynctacticIcon from "../../../../reusable_components/LKIcons/LKMorphoSyntacticIcon";
import SentimentIcon from "../../../../reusable_components/LKIcons/LKSentimentIcon";
import KeyWordExtractorIcon from "../../../../reusable_components/LKIcons/LKKeyExtractorIcon";
import MultiExtractorIcon from "../../../../reusable_components/LKIcons/LKMultiWordIcon";
import TripletIcon from "../../../../reusable_components/LKIcons/LKTripletIcon";
import SyntacticAnalyzerIcon from "../../../../reusable_components/LKIcons/LKSyntacticAnalizerIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import CommonServices from "../../../../services/CommonServices";
import {
  parseMorphosyntacticResult,
  parseSyntacticResult,
  parseSentimentResult,
  parseKeyWordResult,
  parseMultiWordResult,
  parseTripletsResult,
} from "../utils/pageParsers";
import styles from "./AnalysisPage.module.scss";
import LKAdsense from "../../../../reusable_components/LKAdsense/LKAdsense";
import summarize from "../../../../utils/summarize";

const AnalysisPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState({
    summary: undefined,
    morphosyntactic: undefined,
    syntactic: undefined,
    sentiment: undefined,
    keyword: undefined,
    multiword: undefined,
    triplets: undefined,
  });
  const { state } = useContext(AppContext);

  const onSubmitClick = useCallback(async () => {
    // TODO Needs refactor
    const newResult = {};
    //Summary

    const summary = summarize(text, 20, "pt");
    newResult.summary = summary;

    // Morphosyntactic
    try {
      const response = await CommonServices.wordFrequency({
        lang: state.language,
        text,
      });
      newResult.morphosyntactic = response;
    } catch (e) {
      console.log(e);
    }
    // Syntactic
    try {
      const response = await CommonServices.syntacticAnalizer({
        lang: state.language,
        text,
      });
      newResult.syntactic = response;
    } catch (e) {
      console.log(e);
    }
    //Sentiment
    try {
      const response = await CommonServices.sentimentAnalizer({
        lang: state.language,
        text,
      });
      newResult.sentiment = response;
    } catch (e) {
      console.log(e);
    }

    //Keyword
    try {
      const response = await CommonServices.keyWordExtractor({
        lang: state.language,
        text,
      });

      newResult.keyword = response;
    } catch (e) {
      console.log(e);
    }

    //MultiWord
    try {
      const response = await CommonServices.multiWordExtractor({
        lang: state.language,
        text,
      });
      newResult.multiword = response;
    } catch (e) {
      console.log(e);
    }

    //Triplets
    try {
      const response = await CommonServices.tripletsExtractor({
        lang: state.language,
        text,
      });
      newResult.triplets = response;
    } catch (e) {
      console.log(e);
    }
    //Save results
    setResult(newResult);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_FULL_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_FULL_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      <LKAdsense position="bottom" />
      {result.summary && (
        <LKResultContainer Icon={SummaryIcon} title={t("MODULE_SUMM_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_SUMM_LABEL")}</LKTypography>
            <LKTypography variant="base">{result.summary}</LKTypography>
          </div>
        </LKResultContainer>
      )}
      {result.morphosyntactic && (
        <LKResultContainer
          Icon={MorphoSynctacticIcon}
          title={t("MODULE_POST_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_POST_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_POST_RESULT_WORD"),
                t("MODULE_POST_RESULT_LEMMA"),
                t("MODULE_POST_RESULT_TAG"),
              ]}
              values={parseMorphosyntacticResult(
                result.morphosyntactic,
                state.language
              )}
            />
          </div>
        </LKResultContainer>
      )}
      {result.syntactic && (
        <LKResultContainer
          Icon={SyntacticAnalyzerIcon}
          title={t("MODULE_DEPP_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_DEPP_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_DEPP_RESULT_DP"),
                t("MODULE_DEPP_RESULT_D"),
                t("MODULE_DEPP_RESULT_DC"),
                t("MODULE_DEPP_RESULT_HP"),
                t("MODULE_DEPP_RESULT_SR"),
              ]}
              values={parseSyntacticResult(result.syntactic, state.language)}
            />
          </div>
        </LKResultContainer>
      )}
      {result.sentiment && (
        <LKResultContainer Icon={SentimentIcon} title={t("MODULE_SENT_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_SENT_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_SENT_RESULT_SENTIMENT"),
                t("MODULE_SENT_RESULT_STATS"),
              ]}
              values={parseSentimentResult(result.sentiment)}
            />
          </div>
        </LKResultContainer>
      )}
      {result.keyword && (
        <LKResultContainer
          Icon={KeyWordExtractorIcon}
          title={t("MODULE_KEYW_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_KEYW_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_KEYW_RESULT_WORD"),
                t("MODULE_KEYW_RESULT_WEIGHT"),
                t("MODULE_KEYW_RESULT_TAG"),
              ]}
              values={parseKeyWordResult(result.keyword, state.language, 100)}
            />
          </div>
        </LKResultContainer>
      )}
      {result.multiword && (
        <LKResultContainer
          Icon={MultiExtractorIcon}
          title={t("MODULE_MULT_LABEL")}
        >
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_MULT_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_MULT_RESULT_WORD"),
                t("MODULE_MULT_RESULT_WEIGHT"),
                t("MODULE_MULT_RESULT_TAG"),
              ]}
              values={parseMultiWordResult(
                result.multiword,
                state.language,
                100
              )}
            />
          </div>
        </LKResultContainer>
      )}
      {result.triplets && (
        <LKResultContainer Icon={TripletIcon} title={t("MODULE_TRIP_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTypography variant="h2">{t("MODULE_TRIP_LABEL")}</LKTypography>
            <LKTable
              headers={[
                t("MODULE_TRIP_RESULT_SUBJ"),
                t("MODULE_TRIP_RESULT_REL"),
                t("MODULE_TRIP_RESULT_OBJ"),
              ]}
              values={parseTripletsResult(result.triplets)}
            />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default AnalysisPage;
