import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import styles from "./LKTable.module.scss";
import "./imported/SuperResponsiveTableStyle.scss";

// headers must be array of strings (header)
// values must be array of arrays of strings (each row)
const LKTable = ({ headers, values, ...props }) => {
  return (
    <Table>
      <Thead>
        <Tr className={styles.cell}>
          {headers?.map((item, index) => (
            <Th key={`${index}${item}`}>{item}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {values?.map((row, rowIndex) => (
          <Tr key={rowIndex} className={styles.cell}>
            {row?.map((rowItem, rowItemIndex) => {
              return <Td key={`${rowItemIndex}${rowItem}`}>{rowItem}</Td>;
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default LKTable;
