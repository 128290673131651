import React, { useCallback, useContext } from "react";
import styles from "./LanguageButton.module.scss";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import LKDropdown from "../../reusable_components/LKDropdown/LKDropdown";
import { useTranslation } from "react-i18next";

const types = {
  en: {
    route: "/en",
    text: "EN",
  },
  es: {
    route: "/es",
    text: "ES",
  },
  pt: {
    route: "/pt",
    text: "PT",
  },
  gl: {
    route: "/gl",
    text: "GAL",
  },
};

const options = [
  { value: "en", label: "EN" },
  { value: "es", label: "ES" },
  { value: "pt", label: "PT" },
  { value: "gl", label: "GL" },
];

const LanguageSelector = () => {
  const [t] = useTranslation();
  let history = useHistory();
  const { state, dispatch } = useContext(AppContext);

  const onChange = useCallback(
    ({ value }) => {
      dispatch({ type: "CHANGE_LANGUAGE", value: { language: value } });
      history.push(types[value]?.route);
    },
    [history, dispatch]
  );

  return (
    <div className={styles.container}>
      <LKDropdown
        options={options}
        placeholder={t("LANG_SELECT")}
        onChange={onChange}
        value={state.language}
      />
    </div>
  );
};

export default LanguageSelector;
