export const es = {
  code: "es",
};
export const en = {
  code: "en",
};
export const pt = {
  code: "pt",
};
export const gl = {
  code: "gl",
};
