import LKAxios from "./LKAxios";

const CommonServices = {
  async summarize({ lang, text, percent }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("output", percent);
    params.append("text", text);
    const response = await LKAxios.service.post("/sum", params);
    return response;
  },
  async conjugator({ lang, output, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("output", output);
    params.append("text", text);
    const response = await LKAxios.service.post("/conj", params);
    return response;
  },
  async avaliator({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/aval", params);
    return response;
  },
  async wordFrequency({ lang, output = "nec", text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("output", output);
    params.append("text", text);
    const response = await LKAxios.service.post("/tagger", params);
    return response;
  },
  async keyWordContext({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/kwic", params);
    return response;
  },
  async syntacticAnalizer({ lang, output = "conll", text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("output", output);
    params.append("text", text);
    const response = await LKAxios.service.post("/dep", params);
    return response;
  },
  async sentimentAnalizer({ lang, output = "conll", text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("output", output);
    params.append("text", text);
    const response = await LKAxios.service.post("/sent", params);
    return response;
  },
  async languageIdentificator({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/recog", params);
    return response;
  },
  async keyWordExtractor({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/key", params);
    return response;
  },
  async multiWordExtractor({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/mwe", params);
    return response;
  },
  async tripletsExtractor({ lang, text }) {
    const params = new URLSearchParams();
    params.append("lang", lang);
    params.append("text", text);
    const response = await LKAxios.service.post("/rel", params);
    return response;
  },
};

export default CommonServices;
