function deppattern_code_to_readable_en(code){

            var dependencies ={};
                dependencies['AdjnL'] = 'Modifier';
                dependencies['AdjnR'] = 'Modifier';
                dependencies['AtrR'] = 'Verbal predicative';
                dependencies['SpecL'] = 'Nominal Specifier (or determiner)';
                dependencies['SpecR'] = 'Nominal Specifier (or determiner)';
                dependencies['VSpecL'] = 'Specifier';
                dependencies['VSpecR'] = 'Epecifier';
                dependencies['VSpecLocL'] = 'Specifier with preposition';
                dependencies['VSpecLocR'] = 'Specifier with preposition';
                dependencies['ClitL'] = 'Clitic relation';
                dependencies['ClitR'] = 'Clitic relation';
                dependencies['VperL'] = '';
                dependencies['VperR'] = '';
                dependencies['CprepL'] = 'Prepositional object of a noun';
                dependencies['CprepR'] = 'Prepositional object of a noun';
                dependencies['SubjL'] = 'Subject';
                dependencies['SubjR'] = 'Subject';
                dependencies['DobjL'] = 'Direct object';
                dependencies['DobjR'] = 'Direct object';
                dependencies['IobjR'] = 'Indirect object';
                dependencies['CregR'] = '';
                dependencies['CregL'] = '';
                dependencies['CircR'] = 'Prepositional object of a verb';
                dependencies['CircL'] = 'Prepositional object of a verb';
                dependencies['Circ2R'] = 'Prepositional object of a verb';
                dependencies['Circ2L'] = 'Prepositional object of a verb';
                dependencies['Saxon'] = 'Saxon genitive';
                dependencies['BipolarL'] = '';
                dependencies['BipolarR'] = '';
                dependencies['CprepAntL'] = '';
                dependencies['PcleL'] = 'Verb particle';
                dependencies['PcleR'] = 'Verb particle';
                dependencies['AgL'] = '';
                dependencies['CoordL'] = 'Coordination';
                dependencies['CoordR'] = 'Coordination';
                dependencies['PunctL'] = 'Punctuation markup';
                dependencies['PunctR'] = 'Punctuation markup';
                dependencies['TermR'] = 'Terminal of a preposition';
                dependencies['PrepR'] = 'Prepositional complement of a coordinated structure';
                dependencies['DobjPrepR'] = 'Direct object with preposition "a" ';



                return dependencies[code];



}

function deppattern_code_to_readable_pt(codigo){

            var dependencias ={};
                dependencias['AdjnL'] = 'Modificador';
                dependencias['AdjnR'] = 'Modificador';
                dependencias['AtrR'] = 'Atributo verbal';
                dependencias['SpecL'] = 'Especificador nominal (ou determinante)';
                dependencias['SpecR'] = 'Especificador nominal (ou determinante)';
                dependencias['VSpecL'] = 'Especificador';
                dependencias['VSpecR'] = 'Especificador';
                dependencias['VSpecLocL'] = 'Especificador con preposición';
                dependencias['VSpecLocR'] = 'Especificador con preposición';
                dependencias['ClitL'] = 'Relação clitic';
                dependencias['ClitR'] = 'Relação clitic';
                dependencias['VperL'] = '';
                dependencias['VperR'] = '';
                dependencias['CprepL'] = 'Complemento nominal';
                dependencias['CprepR'] = 'Complemento nominal';
                dependencias['SubjL'] = 'Sujeito';
                dependencias['SubjR'] = 'Sujeito';
                dependencias['DobjL'] = 'Objeto direto';
                dependencias['DobjR'] = 'Objeto direto';
                dependencias['IobjR'] = 'Objeto indireto';
                dependencias['CregR'] = 'Complemento de regime';
                dependencias['CregL'] = 'Complemento de regime';
                dependencias['CircR'] = 'Complemento circunstancial';
                dependencias['CircL'] = 'Complemento circunstancial';
                dependencias['Circ2R'] = 'Complemento circunstancial';
                dependencias['Circ2L'] = 'Complemento circunstancial';
                dependencias['Saxon'] = 'Genitivo Saxão';
                dependencias['BipolarL'] = '';
                dependencias['BipolarR'] = '';
                dependencias['CprepAntL'] = '';
                dependencias['PcleL'] = 'Partícula verbal';
                dependencias['PcleR'] = 'Partícula verbal';
                dependencias['AgL'] = '';
                dependencias['CoordL'] = 'Coordenação';
                dependencias['CoordR'] = 'Coordenação';
                dependencias['PunctL'] = 'Puntuação';
                dependencias['PunctR'] = 'Puntuação';
                dependencias['TermR'] = 'Terminal de uma preposição';
                dependencias['PrepR'] = 'Complemento proposicional de uma estrutura coordinada';
                dependencias['DobjPrepR'] = 'Complemento directo com preposição "a" ';



                return dependencias[codigo];



}

function deppattern_code_to_readable_es(codigo){

            var dependencias ={};
                dependencias['AdjnL'] = 'Modificador';
                dependencias['AdjnR'] = 'Modificador';
                dependencias['AtrR'] = 'Atributo verbal';
                dependencias['SpecL'] = 'Especificador nominal (o determinante)';
                dependencias['SpecR'] = 'Especificador nominal (o determinante)';
                dependencias['VSpecL'] = 'Especificador';
                dependencias['VSpecR'] = 'Especificador';
                dependencias['VSpecLocL'] = 'Especificador con preposición';
                dependencias['VSpecLocR'] = 'Especificador con preposición';
                dependencias['ClitL'] = 'Relación clítica';
                dependencias['ClitR'] = 'Relación clítica';
                dependencias['VperL'] = '';
                dependencias['VperR'] = '';
                dependencias['CprepL'] = 'Complemento nominal';
                dependencias['CprepR'] = 'Complemento nominal';
                dependencias['SubjL'] = 'Sujeto';
                dependencias['SubjR'] = 'Sujeto';
                dependencias['DobjL'] = 'Objeto directo';
                dependencias['DobjR'] = 'Objeto directo';
                dependencias['IobjR'] = 'Objeto indirecto';
                dependencias['CregR'] = 'Complemento de régimen';
                dependencias['CregL'] = 'Complemento de régimen';
                dependencias['CircR'] = 'Complemento circunstancial';
                dependencias['CircL'] = 'Complemento circunstancial';
                dependencias['Circ2R'] = 'Complemento circunstancial';
                dependencias['Circ2L'] = 'Complemento circunstancial';
                dependencias['Saxon'] = 'Genitivo Sajón';
                dependencias['BipolarL'] = '';
                dependencias['BipolarR'] = '';
                dependencias['CprepAntL'] = '';
                dependencias['PcleL'] = 'Partícula verbal';
                dependencias['PcleR'] = 'Partícula verbal';
                dependencias['AgL'] = '';
                dependencias['CoordL'] = 'Coordinación';
                dependencias['CoordR'] = 'Coordinación';
                dependencias['PunctL'] = 'Puntuación';
                dependencias['PunctR'] = 'Puntuación';
                dependencias['TermR'] = 'Terminal de una preposición';
                dependencias['PrepR'] = 'Complemento preposicional de una estructura coordinada';
                dependencias['DobjPrepR'] = 'Complemento directo con preposición "a" ';



                return dependencias[codigo];



}

function deppattern_code_to_readable_gl(codigo){

            var dependencias ={};
                dependencias['AdjnL'] = 'Modificador';
                dependencias['AdjnR'] = 'Modificador';
                dependencias['AtrR'] = 'Atributo verbal';
                dependencias['SpecL'] = 'Especificador nominal (ou determinante)';
                dependencias['SpecR'] = 'Especificador nominal (ou determinante)';
                dependencias['VSpecL'] = 'Especificador';
                dependencias['VSpecR'] = 'Especificador';
                dependencias['VSpecLocL'] = 'Especificador con preposición';
                dependencias['VSpecLocR'] = 'Especificador con preposición';
                dependencias['ClitL'] = 'Relación clítica';
                dependencias['ClitR'] = 'Relación clítica';
                dependencias['VperL'] = '';
                dependencias['VperR'] = '';
                dependencias['CprepL'] = 'Complemento nominal';
                dependencias['CprepR'] = 'Complemento nominal';
                dependencias['SubjL'] = 'Suxeito';
                dependencias['SubjR'] = 'Suxeito';
                dependencias['DobjL'] = 'Obxecto directo';
                dependencias['DobjR'] = 'Obxecto directo';
                dependencias['IobjR'] = 'Obxecto indireto';
                dependencias['CregR'] = 'Complemento de réxime';
                dependencias['CregL'] = 'Complemento de réxime';
                dependencias['CircR'] = 'Complemento circunstancial';
                dependencias['CircL'] = 'Complemento circunstancial';
                dependencias['Circ2R'] = 'Complemento circunstancial';
                dependencias['Circ2L'] = 'Complemento circunstancial';
                dependencias['Saxon'] = 'Genitivo Saxón';
                dependencias['BipolarL'] = '';
                dependencias['BipolarR'] = '';
                dependencias['CprepAntL'] = '';
                dependencias['PcleL'] = 'Partícula verbal';
                dependencias['PcleR'] = 'Partícula verbal';
                dependencias['AgL'] = '';
                dependencias['CoordL'] = 'Coordinación';
                dependencias['CoordR'] = 'Coordinación';
                dependencias['PunctL'] = 'Puntuación';
                dependencias['PunctR'] = 'Puntuación';
                dependencias['TermR'] = 'Terminal de unha preposición';
                dependencias['PrepR'] = 'Complemento preposicional de unha estructura coordinada';
                dependencias['DobjPrepR'] = 'Complemento directo con preposición "a" ';



                return dependencias[codigo];



}


export function deppattern_code_to_readable(value, lang) {

  switch (lang){
    case 'es':
      return deppattern_code_to_readable_es(value);
    case 'en':
      return deppattern_code_to_readable_en(value);
    case 'gl':
      return deppattern_code_to_readable_gl(value);
    case 'pt':
      return deppattern_code_to_readable_pt(value);
  }

  return value;
}
