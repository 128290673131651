import React, { useState, useCallback, useContext } from "react";
import LKTextArea from "../../../../reusable_components/LKTextArea/LKTextArea";
import LKButton from "../../../../reusable_components/LKButton/LKButton";
import LKWordCounter from "../../../../reusable_components/LKWordCounter/LKWordCounter";
import LKTypography from "../../../../reusable_components/LKTypography/LKTypography";
import LKResultContainer from "../../../../reusable_components/LKResultContainer/LKResultContainer";
import KeyWordIcon from "../../../../reusable_components/LKIcons/LKKeyExtractorIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/appContext";
import LKTable from "../../../../reusable_components/LKTable/LKTable";
import CommonServices from "../../../../services/CommonServices";
import styles from "./KeyWordPage.module.scss";

const headerResult = ["1", "2", "3", "4"];
const parseResult = () => {
  return [
    ["a", "b", "c", "d"],
    ["e", "f", "g", "h"],
    ["i", "j", "k", "l"],
  ];
};

const KeyWordPage = () => {
  const [t] = useTranslation();
  const [text, setText] = useState("");
  const [result, setResult] = useState("");
  const { state } = useContext(AppContext);

  const onSubmitClick = useCallback(async () => {
    const response = await CommonServices.keyWordContext({
      lang: state.language,
      text,
    });
    setResult(response);
  }, [text, state.language]);

  return (
    <div className={styles.container}>
      <LKTypography variant="h2">{t("MODULE_KWIC_LABEL")}</LKTypography>
      <LKTypography variant="base">{t("MODULE_KWIC_DESCRIPTION")}</LKTypography>
      <LKWordCounter characters={text.length} maxCharacters={5000} />
      <LKTextArea
        onChange={(value) => {
          setText(value);
        }}
      />
      <div className={styles.buttonsContainer}>
        <LKButton text={t("INPUT_ANALYZE_BTN")} onClick={onSubmitClick} />
      </div>
      {result !== "" && (
        <LKResultContainer Icon={KeyWordIcon} title={t("MODULE_KWIC_LABEL")}>
          <div className={styles.tableContainer}>
            <LKTable headers={headerResult} values={parseResult()} />
          </div>
        </LKResultContainer>
      )}
    </div>
  );
};

export default KeyWordPage;
