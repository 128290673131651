import * as React from "react";

function LKMultiWordIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={330}
      height={250}
      viewBox="0 0 330 250"
      {...props}
    >
      <g fill={props.fillValue || "#444"}>
        <path d="M28.164 33.921v183.158H131.19V76.372H90.291V33.921H28.164zm69.758 4.534V68.74h30.348L97.922 38.455zm0-12.165l40.899 42.214V224.71H20.532V26.29h77.39z" />
        <path d="M35.501 50.735v3.816h45.79v-3.816h-45.79zm0 11.448v3.816h45.79v-3.816h-45.79zm0 11.447v3.816h45.79V73.63h-45.79zm0 11.448v3.816h87.763v-3.816H35.501zm0 11.861v3.815h87.763v-3.815H35.501zm0 11.867v3.816h87.763v-3.816H35.501zm0 11.924v3.815h87.763v-3.815H35.501zm0 11.862v3.816h87.763v-3.816H35.501zm0 11.871v3.816h87.763v-3.816H35.501zm0 11.862v3.815h87.763v-3.815H35.501zm0 11.862v3.816h87.763v-3.816H35.501zm0 11.871v3.816h87.763v-3.816H35.501zm0 11.862v3.815h87.763v-3.815H35.501zM199.874 33.921v183.158H302.9V76.372h-40.895V33.921h-62.131zm69.764 4.534V68.74h30.343l-30.343-30.285zm0-12.165l40.895 42.214V224.71h-118.29V26.29h77.395z" />
        <path d="M252.997 96.939v3.815h-14.839v-3.815h14.839zM150.153 94.555v10.792l19.97 19.975-19.97 19.97v10.792l30.768-30.762-30.768-30.767zM235.826 96.939v3.815h-14.839v-3.815h14.839zM279.707 85.078v3.816h-14.839v-3.816h14.839zM262.536 85.078v3.816h-18.655v-3.816h18.655zM254.943 124.387v-3.816h7.207v3.816h-7.207zM264.482 124.387v-3.816h14.839v3.816h-14.839zM220.601 136.253v-3.816h14.839v3.816h-14.839zM237.772 136.253v-3.816h18.655v3.816h-18.655zM209.153 183.874v-3.816h14.839v3.816h-14.839zM226.325 183.874v-3.816h14.839v3.816h-14.839zM220.601 195.735v-3.815h14.839v3.815h-14.839zM237.772 195.735v-3.815h18.655v3.815h-18.655zM245.365 156.421v3.816h-7.208v-3.816h7.208zM235.826 156.421v3.816h-14.839v-3.816h14.839zM279.707 144.56v3.816h-14.839v-3.816h14.839zM262.536 144.56v3.816h-14.839v-3.816h14.839zM217.739 66.095v-3.816h7.217v3.816h-7.217zM227.278 66.095v-3.816h14.849v3.816h-14.849z" />
      </g>
    </svg>
  );
}

export default LKMultiWordIcon;
