import React from "react";
import styles from "./LKResultContainer.module.scss";
import Typography from "../LKTypography/LKTypography";

const LKResultContainer = ({ Icon, children, title }) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Icon height={50} width={50} />
        <Typography variant="h2" accent className={styles.title}>
          {title}
        </Typography>
      </div>
      {children}
    </div>
  );
};

export default LKResultContainer;
