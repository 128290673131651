import React, { useCallback } from "react";
import styles from "./LKTypography.module.scss";

// Variants options
// h1 h2 h3 base

const LKTypography = ({ variant, children, accent, className }) => {
  const getText = useCallback(() => {
    switch (variant) {
      case "h1":
        return (
          <h1
            className={`${styles.h1} ${accent ? styles.accent : ""} ${
              className ? className : ""
            }`}
          >
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2
            className={`${styles.h2} ${accent ? styles.accent : ""} ${
              className ? className : ""
            }`}
          >
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3
            className={`${styles.h3} ${accent ? styles.accent : ""} ${
              className ? className : ""
            }`}
          >
            {children}
          </h3>
        );
      case "base":
        return (
          <p
            className={`${styles.base} ${accent ? styles.accent : ""} ${
              className ? className : ""
            }`}
          >
            {children}
          </p>
        );
      default:
        return (
          <span
            className={`${styles.span} ${accent ? styles.accent : ""} ${
              className ? className : ""
            }`}
          >
            {children}
          </span>
        );
    }
  }, [variant, children, accent, className]);

  return getText(variant);
};

export default LKTypography;
