import { code_to_readable } from "../../../../utils/code_to_readable";
import { abbreviation_to_readable } from "../../../../utils/abbreviation_to_readable";
import { deppattern_code_to_readable } from "../../../../utils/deppattern_code_to_readable";

export const parseMorphosyntacticResult = (result, lang) => {
  return result.map(function (line) {
    const rowElements = line.split(" ");
    if (rowElements[0] !== "") {
      return [
        rowElements[0],
        rowElements[1],
        code_to_readable(rowElements[2] || "", lang),
      ];
    }
  });
};

export const parseSyntacticResult = (result, lang) => {
  const rows = result.map((row) => {
    const row_array = row.split("\t");
    return [
      row_array[0],
      row_array[1],
      abbreviation_to_readable(row_array[3] || "", lang),
      row_array[4],
      deppattern_code_to_readable(row_array[6] || "", lang),
    ];
  });

  return rows;
};

export const parseSentimentResult = (result) => {
  const res = result.split("\t");
  return [[res[1], res[2]]];
};

export const parseKeyWordResult = (result, lang, maxResults) => {
  const rows = result.slice(0, maxResults).map((row) => {
    const row_split = row.split("\t");
    return [
      row_split[0],
      row_split[1],
      abbreviation_to_readable(row_split[2], lang),
    ];
  });
  return rows;
};

export const parseMultiWordResult = (result, lang, maxResults) => {
  const rows = result.slice(0, maxResults).map((row) => {
    const row_split = row.split("\t");
    const readable = row_split[2]
      .split("-")
      .map((item) => abbreviation_to_readable(item, lang))
      .join("-");
    return [row_split[0], row_split[1], readable];
  });
  return rows;
};

export const parseTripletsResult = (result) => {
  const rows = result.map((row) => {
    const row_split = row.split("\t");
    return [row_split[1], row_split[2], row_split[3]];
  });
  return rows;
};
