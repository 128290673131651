import React from "react";
import styles from "./MenuButton.module.scss";
import { Link } from "react-router-dom";

const MenuButton = ({ name, to, highlighted }) => {
  return (
    <li
      className={`${styles.menuButton} ${
        highlighted ? styles.highlighted : styles.normal
      }`}
    >
      <Link to={to}>{name}</Link>
    </li>
  );
};

export default MenuButton;
